import React from 'react';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <>
      {/* ============================ Hero Banner  Start================================== */}
      <div className='light-bg hero-banner'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12'>
              <p className='d-inline-flex sm-small text-light rounded-5 px-2 py-1 bg-dark align-items-center'>
                We just launched our service in Mumbai, Maharashtra
                <span className='sm-small px-2 rounded-5 bg-primary text-light ms-2'>
                  New
                </span>
              </p>
              <h2>
                Find Your Real Estate
                <br />
                In India Now.
              </h2>
              <p className='small'>
                Find real estate in 40+ different cities represented by 100+
                leading brokerages.
              </p>
              <div className='full-search-2 eclip-search italian-search hero-search-radius mt-5'>
                <div className='hero-search-content'>
                  <div className='row'>
                    <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 b-r'>
                      <div className='form-group'>
                        <div className='choose-propert-type'>
                          <ul>
                            <li>
                              <div className='form-check'>
                                <label
                                  className='form-check-label'
                                  htmlFor='typbuy'
                                >
                                  Buy
                                </label>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  id='typbuy'
                                  name='typeprt'
                                />
                              </div>
                            </li>
                            <li>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  id='typrent'
                                  name='typeprt'
                                  defaultChecked
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='typrent'
                                >
                                  Rent
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-7 col-lg-6 col-md-5 col-sm-12 px-xl-0 px-lg-0 px-md-0'>
                      <div className='form-group border-start borders'>
                        <div className='position-relative'>
                          <input
                            type='text'
                            className='form-control border-0 ps-5'
                            placeholder='Search for a location'
                          />
                          <div className='position-absolute top-50 start-0 translate-middle-y ms-2'>
                            <span className='svg-icon text-primary svg-icon-2hx'>
                              <svg
                                width={25}
                                height={25}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-2 col-lg-3 col-md-3 col-sm-12'>
                      <div className='form-group'>
                        <button
                          type='button'
                          className='btn btn-dark full-width'
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='searches-lists'>
                <ul>
                  <li>
                    <span>Popular Searches:</span>
                  </li>
                  <li>
                    <Link to='/'>2 BHK</Link>
                  </li>
                  <li>
                    <Link to='/' className='active'>
                      Plot
                    </Link>
                  </li>
                  <li>
                    <Link to='/'>Apartment</Link>
                  </li>
                  <li>
                    <Link to='/'>Pune</Link>
                  </li>
                  <li>
                    <Link to='/'>Villa</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12'>
              <div className>
                <img
                  src='assets/img/side-city-1.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Hero Banner End ================================== */}
      {/* ============================ Category Start ================================== */}
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-10'>
              <div className='sec-heading mb-4 mss'>
                <h2>Choose Property Type</h2>
                <p>
                  Whether your searching for a home or a business space, our
                  service offers a diverse selection to meet your needs, find
                  your ideal property that aligns with your lifestyle, business
                  goals, or investment plans.
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center gx-3 gy-3'>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-house-laptop' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Commercial</h4>
                    <p>12 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-building' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Apartment</h4>
                    <p>16 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-land-mine-on' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Land</h4>
                    <p>55 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-synagogue' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Villa</h4>
                    <p>18 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-mosque' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Row House</h4>
                    <p>42 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-warehouse' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Warehouses</h4>
                    <p>63 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-house' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Private House</h4>
                    <p>12 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-layer-group' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Plots</h4>
                    <p>75 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-city' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Flats</h4>
                    <p>19 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-shop' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Shops</h4>
                    <p>17 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-shop-lock' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Studio</h4>
                    <p>10 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='classical-cats-wrap style_2 shadows'>
                <Link
                  to='/'
                  className='classical-cats-boxs bg-white rounded-4 px-3 py-4'
                >
                  <div className='classical-cats-icon circle bg-light-info text-primary fs-2'>
                    <i className='fa-solid fa-building-columns' />
                  </div>
                  <div className='classical-cats-wrap-content'>
                    <h4>Offices</h4>
                    <p>31 Properties</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='clearfix' />
      {/* ================================ Category End ======================================== */}
      {/* ================================ All Property ========================================= */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-7 col-md-10 text-center'>
              <div className='sec-heading mss'>
                <h2>Featured Property For Sale</h2>
                <p>
                  Explore our curated selection of standout properties currently
                  on the market. Discover homes that combine exceptional
                  location, unique design, and desirable amenities, all waiting
                  to be your new address.
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center g-4'>
            {/* Single Property */}
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
              <div className='property-listing card border-0 rounded-3'>
                <div className='listing-img-wrapper p-3'>
                  <div className='position-relative'>
                    <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                      <div className='label bg-success text-light d-inline-flex align-items-center justify-content-center'>
                        <span className='svg-icon text-light svg-icon-2hx me-1'>
                          <svg
                            width={14}
                            height={14}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              opacity='0.3'
                              d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                              fill='currentColor'
                            />
                            <path
                              d='M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z'
                              fill='currentColor'
                            />
                          </svg>{' '}
                        </span>
                        Verified
                      </div>
                    </div>
                    <div className='list-img-slide'>
                      <div className='click mb-0 rounded-3 overflow-hidden'>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='listing-caption-wrapper px-3'>
                  <div className='listing-detail-wrapper'>
                    <div className='listing-short-detail-wrap'>
                      <div className='listing-short-detail'>
                        <div className='d-flex align-items-center mb-1'>
                          <span className='label bg-light-success text-success prt-type me-2'>
                            For Rent
                          </span>
                          <span className='label bg-light-purple text-purple property-cats'>
                            Apartment
                          </span>
                        </div>
                        <h4 className='listing-name fw-semibold fs-6 mb-0'>
                          <Link
                            to='/single-property-1'
                            className='prt-link-detail'
                          >
                            Equitable Property Group
                          </Link>
                        </h4>
                        <div className='prt-location text-muted-2'>
                          <span className='svg-icon svg-icon-2hx'>
                            <svg
                              width={18}
                              height={18}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.3'
                                d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                fill='currentColor'
                              />
                              <path
                                d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          210 Zirak Road, Canada
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper d-flex align-items-center justify-content-between my-4'>
                    <div className='listing-short-detail-flex'>
                      <h6 className='listing-card-info-price text-primary ps-0 m-0'>
                        ₹78,500
                      </h6>
                    </div>
                    <div className='lst-short-btns-groups d-flex align-items-center'>
                      <Link
                        to='/'
                        className='square--50 circle text-primary bg-light-primary me-2'
                      >
                        <i className='fa-solid fa-code-compare' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-success bg-light-success me-2'
                      >
                        <i className='fa-solid fa-envelope-open-text' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-danger bg-light-danger'
                      >
                        <i className='fa-solid fa-heart-circle-check' />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='lst-detail-footer d-flex align-items-center justify-content-between border-top py-2 px-3'>
                  <div className='footer-first'>
                    <div className='foot-reviews-wrap'>
                      <div className='foot-reviews-stars'>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                      </div>
                      <div className='foot-reviews-subtitle'>
                        <span className='text-muted'>47 Reviews</span>
                      </div>
                    </div>
                  </div>
                  <div className='footer-flex'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between m-0'>
                      <div className='listing-card d-flex align-items-center me-3'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-building-shield fs-sm' />
                        </div>
                        <span className='text-muted-2'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-clone fs-sm' />
                        </div>
                        <span className='text-muted-2'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Single Property */}
            {/* Single Property */}
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
              <div className='property-listing card border-0 rounded-3'>
                <div className='listing-img-wrapper p-3'>
                  <div className='position-relative'>
                    <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                      <div className='label bg-purple text-light d-inline-flex align-items-center justify-content-center'>
                        <span className='svg-icon text-light svg-icon-2hx me-1'>
                          <svg
                            width={14}
                            height={14}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              opacity='0.3'
                              d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                              fill='currentColor'
                            />
                            <path
                              d='M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z'
                              fill='currentColor'
                            />
                            <path
                              d='M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z'
                              fill='currentColor'
                            />
                          </svg>{' '}
                        </span>
                        SuperAgent
                      </div>
                    </div>
                    <div className='list-img-slide'>
                      <div className='click mb-0 rounded-3 overflow-hidden'>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='listing-caption-wrapper px-3'>
                  <div className='listing-detail-wrapper'>
                    <div className='listing-short-detail-wrap'>
                      <div className='listing-short-detail'>
                        <div className='d-flex align-items-center mb-1'>
                          <span className='label bg-light-success text-success prt-type me-2'>
                            For Rent
                          </span>
                          <span className='label bg-light-purple text-purple property-cats'>
                            Apartment
                          </span>
                        </div>
                        <h4 className='listing-name fw-semibold fs-6 mb-0'>
                          <Link
                            to='/single-property-1'
                            className='prt-link-detail'
                          >
                            Purple Flatiron House
                          </Link>
                        </h4>
                        <div className='prt-location text-muted-2'>
                          <span className='svg-icon svg-icon-2hx'>
                            <svg
                              width={18}
                              height={18}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.3'
                                d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                fill='currentColor'
                              />
                              <path
                                d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          210 Zirak Road, Canada
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper d-flex align-items-center justify-content-between my-4'>
                    <div className='listing-short-detail-flex'>
                      <h6 className='listing-card-info-price text-primary ps-0 m-0'>
                        ₹67,000
                      </h6>
                    </div>
                    <div className='lst-short-btns-groups d-flex align-items-center'>
                      <Link
                        to='/'
                        className='square--50 circle text-primary bg-light-primary me-2'
                      >
                        <i className='fa-solid fa-code-compare' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-success bg-light-success me-2'
                      >
                        <i className='fa-solid fa-envelope-open-text' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-danger bg-light-danger'
                      >
                        <i className='fa-solid fa-heart-circle-check' />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='lst-detail-footer d-flex align-items-center justify-content-between border-top py-2 px-3'>
                  <div className='footer-first'>
                    <div className='foot-reviews-wrap'>
                      <div className='foot-reviews-stars'>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                      </div>
                      <div className='foot-reviews-subtitle'>
                        <span className='text-muted'>102 Reviews</span>
                      </div>
                    </div>
                  </div>
                  <div className='footer-flex'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between m-0'>
                      <div className='listing-card d-flex align-items-center me-3'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-building-shield fs-sm' />
                        </div>
                        <span className='text-muted-2'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-clone fs-sm' />
                        </div>
                        <span className='text-muted-2'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Single Property */}
            {/* Single Property */}
            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
              <div className='property-listing card border-0 rounded-3'>
                <div className='listing-img-wrapper p-3'>
                  <div className='position-relative'>
                    <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                      <div className='label bg-success text-light d-inline-flex align-items-center justify-content-center'>
                        <span className='svg-icon text-light svg-icon-2hx me-1'>
                          <svg
                            width={14}
                            height={14}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              opacity='0.3'
                              d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                              fill='currentColor'
                            />
                            <path
                              d='M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z'
                              fill='currentColor'
                            />
                          </svg>{' '}
                        </span>
                        Verified
                      </div>
                      <div className='label bg-danger text-light d-inline-flex align-items-center justify-content-center ms-1'>
                        <span className='svg-icon text-light svg-icon-2hx me-1'>
                          <svg
                            width={14}
                            height={14}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z'
                              fill='currentColor'
                            />
                            <path
                              d='M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z'
                              fill='currentColor'
                            />
                            <path
                              d='M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48884 12.8122 9.48884 9.48886C9.48884 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32556 3.42859 3.42857 7.29209 3.42857 12C3.42857 16.7079 7.32556 20.5714 12.0335 20.5714Z'
                              fill='currentColor'
                            />
                            <path
                              d='M13.0379 7.47998C13.8688 7.47998 14.5446 8.15585 14.5446 8.98668C14.5446 9.26428 14.7693 9.48891 15.0469 9.48891C15.3245 9.48891 15.5491 9.26428 15.5491 8.98668C15.5491 8.15585 16.225 7.47998 17.0558 7.47998C17.3334 7.47998 17.558 7.25535 17.558 6.97775C17.558 6.70015 17.3334 6.47552 17.0558 6.47552C16.225 6.47552 15.5491 5.76616 15.5491 4.93534C15.5491 4.65774 15.3245 4.43311 15.0469 4.43311C14.7693 4.43311 14.5446 4.65774 14.5446 4.93534C14.5446 5.76616 13.8688 6.47552 13.0379 6.47552C12.7603 6.47552 12.5357 6.70015 12.5357 6.97775C12.5357 7.25535 12.7603 7.47998 13.0379 7.47998Z'
                              fill='currentColor'
                            />
                          </svg>{' '}
                        </span>
                        New
                      </div>
                    </div>
                    <div className='list-img-slide'>
                      <div className='click mb-0 rounded-3 overflow-hidden'>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to='/single-property-1'>
                            <img
                              src='https://via.placeholder.com/1200x850'
                              className='img-fluid'
                              alt=''
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='listing-caption-wrapper px-3'>
                  <div className='listing-detail-wrapper'>
                    <div className='listing-short-detail-wrap'>
                      <div className='listing-short-detail'>
                        <div className='d-flex align-items-center mb-1'>
                          <span className='label bg-light-success text-success prt-type me-2'>
                            For Rent
                          </span>
                          <span className='label bg-light-purple text-purple property-cats'>
                            Apartment
                          </span>
                        </div>
                        <h4 className='listing-name fw-semibold fs-6 mb-0'>
                          <Link
                            to='/single-property-1'
                            className='prt-link-detail'
                          >
                            Rustic Reunion Tower
                          </Link>
                        </h4>
                        <div className='prt-location text-muted-2'>
                          <span className='svg-icon svg-icon-2hx'>
                            <svg
                              width={18}
                              height={18}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.3'
                                d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                fill='currentColor'
                              />
                              <path
                                d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                          210 Zirak Road, Canada
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper d-flex align-items-center justify-content-between my-4'>
                    <div className='listing-short-detail-flex'>
                      <h6 className='listing-card-info-price text-primary ps-0 m-0'>
                        ₹87,900
                      </h6>
                    </div>
                    <div className='lst-short-btns-groups d-flex align-items-center'>
                      <Link
                        to='/'
                        className='square--50 circle text-primary bg-light-primary me-2'
                      >
                        <i className='fa-solid fa-code-compare' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-success bg-light-success me-2'
                      >
                        <i className='fa-solid fa-envelope-open-text' />
                      </Link>
                      <Link
                        to='/'
                        className='square--50 circle text-danger bg-light-danger'
                      >
                        <i className='fa-solid fa-heart-circle-check' />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='lst-detail-footer d-flex align-items-center justify-content-between border-top py-2 px-3'>
                  <div className='footer-first'>
                    <div className='foot-reviews-wrap'>
                      <div className='foot-reviews-stars'>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                        <span>
                          <i className='fa-solid fa-star text-warning fs-sm' />
                        </span>
                      </div>
                      <div className='foot-reviews-subtitle'>
                        <span className='text-muted'>89 Reviews</span>
                      </div>
                    </div>
                  </div>
                  <div className='footer-flex'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between m-0'>
                      <div className='listing-card d-flex align-items-center me-3'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-building-shield fs-sm' />
                        </div>
                        <span className='text-muted-2'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--30 text-muted-2 fs-sm circle gray-simple me-2'>
                          <i className='fa-solid fa-clone fs-sm' />
                        </div>
                        <span className='text-muted-2'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Single Property */}
          </div>
          <div className='row align-items-center justify-content-center'>
            <div className='col-lg-12 col-md-12 col-sm-12 text-center mt-5'>
              <Link
                to='/listings-list-with-sidebar'
                className='btn btn-primary px-md-5 rounded'
              >
                Browse More Properties
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ All Featured Property ================================== */}
      {/* ============================ Property Location Start ================================== */}
      <section>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-7 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Find Best Locations</h2>
                <p>
                  Embark on a journey to discover the best locations tailored to
                  your preferences. From bustling city centers to serene
                  suburbs, find the perfect setting for your dream property.
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center g-xl-4 g-md-3 g-4'>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6'>
              <div className='location-property-wrap rounded-4 p-2'>
                <div className='location-property-thumb rounded-4'>
                  <Link to='/listings-list-with-sidebar'>
                    <img
                      src='https://via.placeholder.com/650x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='location-property-content'>
                  <div className='lp-content-flex'>
                    <h4 className='lp-content-title'>Pune, Maharashtra</h4>
                    <span className='text-muted-2'>95 Properties</span>
                  </div>
                  <div className='lp-content-right'>
                    <Link
                      to='/listings-list-with-sidebar'
                      className='text-primary'
                    >
                      <span className='svg-icon svg-icon-2hx'>
                        <svg
                          width={40}
                          height={40}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            fill='currentColor'
                          />
                          <path
                            d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6'>
              <div className='location-property-wrap rounded-4 p-2'>
                <div className='location-property-thumb rounded-4'>
                  <Link to='/listings-list-with-sidebar'>
                    <img
                      src='https://via.placeholder.com/650x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='location-property-content'>
                  <div className='lp-content-flex'>
                    <h4 className='lp-content-title'>
                      Aurangabad, Maharashtra
                    </h4>
                    <span className='text-muted-2'>95 Properties</span>
                  </div>
                  <div className='lp-content-right'>
                    <Link
                      to='/listings-list-with-sidebar'
                      className='text-primary'
                    >
                      <span className='svg-icon svg-icon-2hx'>
                        <svg
                          width={40}
                          height={40}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            fill='currentColor'
                          />
                          <path
                            d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6'>
              <div className='location-property-wrap rounded-4 p-2'>
                <div className='location-property-thumb rounded-4'>
                  <Link to='/listings-list-with-sidebar'>
                    <img
                      src='https://via.placeholder.com/650x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='location-property-content'>
                  <div className='lp-content-flex'>
                    <h4 className='lp-content-title'>Dubai, UAE</h4>
                    <span className='text-muted-2'>95 Properties</span>
                  </div>
                  <div className='lp-content-right'>
                    <Link
                      to='/listings-list-with-sidebar'
                      className='text-primary'
                    >
                      <span className='svg-icon svg-icon-2hx'>
                        <svg
                          width={40}
                          height={40}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            fill='currentColor'
                          />
                          <path
                            d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6'>
              <div className='location-property-wrap rounded-4 p-2'>
                <div className='location-property-thumb rounded-4'>
                  <Link to='/listings-list-with-sidebar'>
                    <img
                      src='https://via.placeholder.com/650x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='location-property-content'>
                  <div className='lp-content-flex'>
                    <h4 className='lp-content-title'>Mumbai, Maharashtra</h4>
                    <span className='text-muted-2'>95 Properties</span>
                  </div>
                  <div className='lp-content-right'>
                    <Link
                      to='/listings-list-with-sidebar'
                      className='text-primary'
                    >
                      <span className='svg-icon svg-icon-2hx'>
                        <svg
                          width={40}
                          height={40}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            opacity='0.3'
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={5}
                            fill='currentColor'
                          />
                          <path
                            d='M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 text-center mt-5'>
              <Link
                to='/listings-list-with-sidebar'
                className='btn btn-primary px-md-5 rounded'
              >
                Browse More Locations
              </Link>
            </div>
          </div>
        </div>
      </section>
      <hr className='opacity-25' />
      {/* ============================ Property Location End ================================== */}
      {/* ============================ All Property ================================== */}
      <section>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Featured Property For Sale</h2>
                <p>
                  Explore our curated selection of standout properties currently
                  on the market. Discover homes that combine exceptional
                  location, unique design, and desirable amenities, all waiting
                  to be your new address.
                </p>
              </div>
            </div>
          </div>
          <div className='row list-layout'>
            {/* Single Property Start */}
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <div className='property-listing property-1 bg-white p-2 rounded border'>
                <div className='listing-img-wrapper'>
                  <Link to='/single-property-2'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid mx-auto rounded'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='listing-content'>
                  <div className='listing-detail-wrapper-box'>
                    <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                      <div className='listing-short-detail'>
                        <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                          For Sale
                        </span>
                        <h4 className='listing-name mb-0'>
                          <Link to='/single-property-2'>
                            Adobe Property Advisors
                          </Link>
                        </h4>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs' />
                          <span className='reviews_text fs-sm text-muted ms-2'>
                            (42 Reviews)
                          </span>
                        </div>
                      </div>
                      <div className='list-price'>
                        <h6 className='listing-card-info-price text-primary'>
                          ₹120M
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-building-shield fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-bed fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3 Beds</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-clone fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                  <div className='listing-footer-wrapper'>
                    <div className='listing-locate'>
                      <span className='listing-location text-muted-2'>
                        <i className='fa-solid fa-location-pin me-1' />
                        Quice Market, Canada
                      </span>
                    </div>
                    <div className='listing-detail-btn'>
                      <Link
                        to='/single-property-2'
                        className='btn btn-sm px-4 fw-medium btn-primary'
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Property End */}
            {/* Single Property Start */}
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <div className='property-listing property-1 bg-white p-2 rounded border'>
                <div className='listing-img-wrapper'>
                  <Link to='/single-property-2'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid mx-auto rounded'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='listing-content'>
                  <div className='listing-detail-wrapper-box'>
                    <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                      <div className='listing-short-detail'>
                        <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                          For Sale
                        </span>
                        <h4 className='listing-name mb-0'>
                          <Link to='/single-property-2'>
                            Agile Real Estate Group
                          </Link>
                        </h4>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs' />
                          <span className='reviews_text fs-sm text-muted ms-2'>
                            (34 Reviews)
                          </span>
                        </div>
                      </div>
                      <div className='list-price'>
                        <h6 className='listing-card-info-price text-primary'>
                          ₹132M
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-building-shield fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-bed fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3 Beds</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-clone fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                  <div className='listing-footer-wrapper'>
                    <div className='listing-locate'>
                      <span className='listing-location text-muted-2'>
                        <i className='fa-solid fa-location-pin me-1' />
                        Quice Market, Canada
                      </span>
                    </div>
                    <div className='listing-detail-btn'>
                      <Link
                        to='/single-property-2'
                        className='btn btn-sm px-4 fw-medium btn-primary'
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Property End */}
            {/* Single Property Start */}
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <div className='property-listing property-1 bg-white p-2 rounded border'>
                <div className='listing-img-wrapper'>
                  <Link to='/single-property-2'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid mx-auto rounded'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='listing-content'>
                  <div className='listing-detail-wrapper-box'>
                    <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                      <div className='listing-short-detail'>
                        <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                          For Sale
                        </span>
                        <h4 className='listing-name mb-0'>
                          <Link to='/single-property-2'>
                            Bluebell Real Estate
                          </Link>
                        </h4>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs' />
                          <span className='reviews_text fs-sm text-muted ms-2'>
                            (124 Reviews)
                          </span>
                        </div>
                      </div>
                      <div className='list-price'>
                        <h6 className='listing-card-info-price text-primary'>
                          ₹127M
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-building-shield fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-bed fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3 Beds</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-clone fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                  <div className='listing-footer-wrapper'>
                    <div className='listing-locate'>
                      <span className='listing-location text-muted-2'>
                        <i className='fa-solid fa-location-pin me-1' />
                        Quice Market, Canada
                      </span>
                    </div>
                    <div className='listing-detail-btn'>
                      <Link
                        to='/single-property-2'
                        className='btn btn-sm px-4 fw-medium btn-primary'
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Property End */}
            {/* Single Property Start */}
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <div className='property-listing property-1 bg-white p-2 rounded border'>
                <div className='listing-img-wrapper'>
                  <Link to='/single-property-2'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid mx-auto rounded'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='listing-content'>
                  <div className='listing-detail-wrapper-box'>
                    <div className='listing-detail-wrapper d-flex align-items-center justify-content-between'>
                      <div className='listing-short-detail'>
                        <span className='label bg-light-danger text-danger d-inline-flex mb-1'>
                          For Sale
                        </span>
                        <h4 className='listing-name mb-0'>
                          <Link to='/single-property-2'>
                            Strive Partners Realty
                          </Link>
                        </h4>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <i className='fas fa-star fs-xs filled' />
                          <span className='reviews_text fs-sm text-muted ms-2'>
                            (56 Reviews)
                          </span>
                        </div>
                      </div>
                      <div className='list-price'>
                        <h6 className='listing-card-info-price text-primary'>
                          ₹132M
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='price-features-wrapper'>
                    <div className='list-fx-features d-flex align-items-center justify-content-between mt-3 mb-1'>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-building-shield fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3BHK</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-bed fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>3 Beds</span>
                      </div>
                      <div className='listing-card d-flex align-items-center'>
                        <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                          <i className='fa-solid fa-clone fs-xs' />
                        </div>
                        <span className='text-muted-2 fs-sm'>1800 SQFT</span>
                      </div>
                    </div>
                  </div>
                  <div className='listing-footer-wrapper'>
                    <div className='listing-locate'>
                      <span className='listing-location text-muted-2'>
                        <i className='fa-solid fa-location-pin me-1' />
                        Quice Market, Canada
                      </span>
                    </div>
                    <div className='listing-detail-btn'>
                      <Link
                        to='/single-property-2'
                        className='btn btn-sm px-4 fw-medium btn-primary'
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Property End */}
          </div>
          {/* Pagination */}
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 text-center mt-4'>
              <Link
                to='/listings-list-with-sidebar'
                className='btn btn-primary px-lg-5 rounded'
              >
                Browse More Properties
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ All Featured Property ================================== */}
      {/* ============================ Latest Property For Sale Start ================================== */}
      <section className='bg-light'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-xl-3 col-lg-4 col-md-4 col-sm-12'>
              <div className='trustscrore-wrap'>
                <div className='trustscrore-title'>
                  <h2>4.8</h2>
                </div>
                <div className='trustscrore-rates'>
                  <div className='trustscrore-stars'>
                    <span>
                      <i className='fa-solid fa-star' />
                    </span>
                    <span>
                      <i className='fa-solid fa-star' />
                    </span>
                    <span>
                      <i className='fa-solid fa-star' />
                    </span>
                    <span>
                      <i className='fa-solid fa-star' />
                    </span>
                    <span>
                      <i className='fa-solid fa-star' />
                    </span>
                  </div>
                  <div className='trustscrore-stars-title'>
                    <span>Trustscore on 176 Reviews</span>
                  </div>
                </div>
                <div className='trustscrore-info'>
                  <div className='trustscrore-subtitle'>
                    <span>Check all reviews on</span>
                  </div>
                  <div className='trustscrore-brand'>
                    <img
                      src='assets/img/brand.png'
                      className='img-fluid'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-8 col-lg-8 col-md-8 col-sm-12'>
              <div className='single-reviews'>
                {/* Single Item */}
                <div className='single-item-reviews'>
                  <div className='single-item-reviews-desc'>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                  </div>
                  <div className='single-item-reviews-info'>
                    <h4>Harman Preet</h4>
                    <p className='text-primary'>Google Manager</p>
                  </div>
                </div>
                {/* Single Item */}
                <div className='single-item-reviews'>
                  <div className='single-item-reviews-desc'>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                  </div>
                  <div className='single-item-reviews-info'>
                    <h4>Bharti Patel</h4>
                    <p className='text-primary'>BootstrapWire Manager</p>
                  </div>
                </div>
                {/* Single Item */}
                <div className='single-item-reviews'>
                  <div className='single-item-reviews-desc'>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                  </div>
                  <div className='single-item-reviews-info'>
                    <h4>Dhananjay Verma</h4>
                    <p className='text-primary'>Themezhub Manager</p>
                  </div>
                </div>
                {/* Single Item */}
                <div className='single-item-reviews'>
                  <div className='single-item-reviews-desc'>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                  </div>
                  <div className='single-item-reviews-info'>
                    <h4>Shreekant Tripathi</h4>
                    <p className='text-primary'>Megroo Developer</p>
                  </div>
                </div>
                {/* Single Item */}
                <div className='single-item-reviews'>
                  <div className='single-item-reviews-desc'>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                  </div>
                  <div className='single-item-reviews-info'>
                    <h4>Rajnish Shukla</h4>
                    <p className='text-primary'>Airbnb Leader</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row align-items-center justify-content-between'>
            <div className='col-xl-12 col-lg-12 col-md-12'>
              <div className='counter-info-groups'>
                <div className='single-counter-wrap'>
                  <div className='single-counter-title'>
                    <h2>
                      ₹<span className='ctr'>52</span>CR
                    </h2>
                  </div>
                  <div className='single-counter-subtitle'>
                    <p>
                      Owned from
                      <br />
                      properties transaction
                    </p>
                  </div>
                </div>
                <div className='single-counter-wrap'>
                  <div className='single-counter-title'>
                    <h2>
                      <span className='ctr'>22</span>K
                    </h2>
                  </div>
                  <div className='single-counter-subtitle'>
                    <p>
                      Properties for
                      <br />
                      Buy &amp; Sell
                    </p>
                  </div>
                </div>
                <div className='single-counter-wrap'>
                  <div className='single-counter-title'>
                    <h2>
                      <span className='ctr'>50</span>
                    </h2>
                  </div>
                  <div className='single-counter-subtitle'>
                    <p>
                      Daily Completed
                      <br />
                      transaction
                    </p>
                  </div>
                </div>
                <div className='single-counter-wrap'>
                  <div className='single-counter-title'>
                    <h2>
                      <span className='ctr'>20</span>K
                    </h2>
                  </div>
                  <div className='single-counter-subtitle'>
                    <p>
                      Happy Customers
                      <br />
                      from AV REALTIES
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Latest Property For Sale End ================================== */}
      {/* ============================ Explore Featured Agents Start ================================== */}
      <section>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Explore Featured Agents</h2>
                <p>
                  Meet our top-rated real estate agents, handpicked for their
                  expertise and commitment to service. Explore profiles of
                  seasoned professionals who can guide you seamlessly through
                  your property journey.
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center g-4'>
            {/* Single Agent */}
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
              <div className='agents-grid card rounded-3 shadow'>
                <div className='agents-grid-wrap'>
                  <div className='fr-grid-thumb mx-auto text-center mt-5 mb-3'>
                    <Link
                      to='/agent-page'
                      className='d-inline-flex p-1 circle border'
                    >
                      <img
                        src='https://via.placeholder.com/800x800'
                        className='img-fluid circle'
                        width={130}
                        alt=''
                      />
                    </Link>
                  </div>
                  <div className='fr-grid-deatil text-center'>
                    <div className='fr-grid-deatil-flex'>
                      <h5 className='fr-can-name mb-0'>
                        <Link to='/'>Anil C. Varma</Link>
                      </h5>
                      <span className='agent-property text-muted-2'>
                        117 Properties
                      </span>
                    </div>
                  </div>
                </div>
                <div className='fr-grid-info d-flex align-items-center justify-content-between px-4 py-4'>
                  <div className='fr-grid-sder'>
                    <ul className='p-0'>
                      <li>
                        <strong>Call:</strong>
                        <span className='fw-medium text-primary ms-2'>
                          +91 8830851524
                        </span>
                      </li>
                      <li>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-muted' />
                          <span className='reviews_text fs-sm text-muted-2'>
                            (42 Reviews)
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className='fr-grid-deatil-flex-right'>
                    <div className='agent-email'>
                      <Link
                        to='/'
                        className='square--50 rounded text-danger bg-light-danger'
                      >
                        <i className='fa-solid fa-envelope-circle-check' />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Agent */}
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
              <div className='agents-grid card rounded-3 shadow'>
                <div className='agents-grid-wrap'>
                  <div className='fr-grid-thumb mx-auto text-center mt-5 mb-3'>
                    <Link
                      to='/agent-page'
                      className='d-inline-flex p-1 circle border'
                    >
                      <img
                        src='https://via.placeholder.com/800x800'
                        className='img-fluid circle'
                        width={130}
                        alt=''
                      />
                    </Link>
                  </div>
                  <div className='fr-grid-deatil text-center'>
                    <div className='fr-grid-deatil-flex'>
                      <h5 className='fr-can-name mb-0'>
                        <Link to='/'>Atul Deshmukh</Link>
                      </h5>
                      <span className='agent-property text-muted-2'>
                        46 Properties
                      </span>
                    </div>
                  </div>
                </div>
                <div className='fr-grid-info d-flex align-items-center justify-content-between px-4 py-4'>
                  <div className='fr-grid-sder'>
                    <ul className='p-0'>
                      <li>
                        <strong>Call:</strong>
                        <span className='fw-medium text-primary ms-2'>
                          +91 9028096903
                        </span>
                      </li>
                      <li>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-muted' />
                          <span className='reviews_text fs-sm text-muted-2'>
                            (33 Reviews)
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className='fr-grid-deatil-flex-right'>
                    <div className='agent-email'>
                      <Link
                        to='/'
                        className='square--50 rounded text-danger bg-light-danger'
                      >
                        <i className='fa-solid fa-envelope-circle-check' />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Agent */}
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
              <div className='agents-grid card rounded-3 shadow'>
                <div className='agents-grid-wrap'>
                  <div className='fr-grid-thumb mx-auto text-center mt-5 mb-3'>
                    <Link
                      to='/agent-page'
                      className='d-inline-flex p-1 circle border'
                    >
                      <img
                        src='https://via.placeholder.com/800x800'
                        className='img-fluid circle'
                        width={130}
                        alt=''
                      />
                    </Link>
                  </div>
                  <div className='fr-grid-deatil text-center'>
                    <div className='fr-grid-deatil-flex'>
                      <h5 className='fr-can-name mb-0'>
                        <Link to='/'>Manish Thakur</Link>
                      </h5>
                      <span className='agent-property text-muted-2'>
                        38 Properties
                      </span>
                    </div>
                  </div>
                </div>
                <div className='fr-grid-info d-flex align-items-center justify-content-between px-4 py-4'>
                  <div className='fr-grid-sder'>
                    <ul className='p-0'>
                      <li>
                        <strong>Call:</strong>
                        <span className='fw-medium text-primary ms-2'>
                          +91 9923790907
                        </span>
                      </li>
                      <li>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-muted' />
                          <span className='reviews_text fs-sm text-muted-2'>
                            (16 Reviews)
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className='fr-grid-deatil-flex-right'>
                    <div className='agent-email'>
                      <Link
                        to='/'
                        className='square--50 rounded text-danger bg-light-danger'
                      >
                        <i className='fa-solid fa-envelope-circle-check' />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single Agent */}
            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
              <div className='agents-grid card rounded-3 shadow'>
                <div className='agents-grid-wrap'>
                  <div className='fr-grid-thumb mx-auto text-center mt-5 mb-3'>
                    <Link
                      to='/agent-page'
                      className='d-inline-flex p-1 circle border'
                    >
                      <img
                        src='https://via.placeholder.com/800x800'
                        className='img-fluid circle'
                        width={130}
                        alt=''
                      />
                    </Link>
                  </div>
                  <div className='fr-grid-deatil text-center'>
                    <div className='fr-grid-deatil-flex'>
                      <h5 className='fr-can-name mb-0'>
                        <Link to='/'>Ameya Tembhekar</Link>
                      </h5>
                      <span className='agent-property text-muted-2'>
                        51 Properties
                      </span>
                    </div>
                  </div>
                </div>
                <div className='fr-grid-info d-flex align-items-center justify-content-between px-4 py-4'>
                  <div className='fr-grid-sder'>
                    <ul className='p-0'>
                      <li>
                        <strong>Call:</strong>
                        <span className='fw-medium text-primary ms-2'>
                          +91 9421683738
                        </span>
                      </li>
                      <li>
                        <div className='fr-can-rating'>
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-warning' />
                          <i className='fas fa-star fs-xs text-muted' />
                          <span className='reviews_text fs-sm text-muted-2'>
                            (28 Reviews)
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className='fr-grid-deatil-flex-right'>
                    <div className='agent-email'>
                      <Link
                        to='/'
                        className='square--50 rounded text-danger bg-light-danger'
                      >
                        <i className='fa-solid fa-envelope-circle-check' />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Pagination */}
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 text-center mt-5'>
              <Link to='/agents' className='btn btn-primary px-lg-5 rounded'>
                Explore More Agents
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className='clearfix' />
      {/* ============================ Explore Featured Agents End ================================== */}
      {/* ============================ Smart Testimonials ================================== */}
      <section className='gray-bg'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Good Reviews by Customers</h2>
                <p>
                  Dive into the experiences of our satisfied customers. Read
                  their glowing reviews to understand why they trust us for
                  their real estate needs.
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-12 col-md-12'>
              <div
                className='smart-textimonials smart-center'
                id='smart-textimonials'
              >
                {/* Single Item */}
                <div className='item'>
                  <div className='item-box'>
                    <div className='smart-tes-author'>
                      <div className='st-author-box'>
                        <div className='st-author-thumb'>
                          <div className='quotes bg-primary'>
                            <i className='fa-solid fa-quote-left' />
                          </div>
                          <img
                            src='https://via.placeholder.com/800x800'
                            className='img-fluid'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                    <div className='smart-tes-content'>
                      <p>
                        Cicero famously orated against his political opponent
                        Lucius Sergius Catilina. Occasionally the first Oration
                        against Catiline is taken specimens.
                      </p>
                    </div>
                    <div className='st-author-info'>
                      <h4 className='st-author-title'>Adam Williams</h4>
                      <span className='st-author-subtitle'>
                        CEO Of Microwoft
                      </span>
                    </div>
                  </div>
                </div>
                {/* Single Item */}
                <div className='item'>
                  <div className='item-box'>
                    <div className='smart-tes-author'>
                      <div className='st-author-box'>
                        <div className='st-author-thumb'>
                          <div className='quotes bg-success'>
                            <i className='fa-solid fa-quote-left' />
                          </div>
                          <img
                            src='https://via.placeholder.com/800x800'
                            className='img-fluid'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                    <div className='smart-tes-content'>
                      <p>
                        Cicero famously orated against his political opponent
                        Lucius Sergius Catilina. Occasionally the first Oration
                        against Catiline is taken specimens.
                      </p>
                    </div>
                    <div className='st-author-info'>
                      <h4 className='st-author-title'>Retha Deowalim</h4>
                      <span className='st-author-subtitle'>CEO Of Apple</span>
                    </div>
                  </div>
                </div>
                {/* Single Item */}
                <div className='item'>
                  <div className='item-box'>
                    <div className='smart-tes-author'>
                      <div className='st-author-box'>
                        <div className='st-author-thumb'>
                          <div className='quotes bg-purple'>
                            <i className='fa-solid fa-quote-left' />
                          </div>
                          <img
                            src='https://via.placeholder.com/800x800'
                            className='img-fluid'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                    <div className='smart-tes-content'>
                      <p>
                        Cicero famously orated against his political opponent
                        Lucius Sergius Catilina. Occasionally the first Oration
                        against Catiline is taken specimens.
                      </p>
                    </div>
                    <div className='st-author-info'>
                      <h4 className='st-author-title'>Sam J. Wasim</h4>
                      <span className='st-author-subtitle'>Pio Founder</span>
                    </div>
                  </div>
                </div>
                {/* Single Item */}
                <div className='item'>
                  <div className='item-box'>
                    <div className='smart-tes-author'>
                      <div className='st-author-box'>
                        <div className='st-author-thumb'>
                          <div className='quotes bg-seegreen'>
                            <i className='fa-solid fa-quote-left' />
                          </div>
                          <img
                            src='https://via.placeholder.com/800x800'
                            className='img-fluid'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                    <div className='smart-tes-content'>
                      <p>
                        Cicero famously orated against his political opponent
                        Lucius Sergius Catilina. Occasionally the first Oration
                        against Catiline is taken specimens.
                      </p>
                    </div>
                    <div className='st-author-info'>
                      <h4 className='st-author-title'>Usan Gulwarm</h4>
                      <span className='st-author-subtitle'>
                        CEO Of Facewarm
                      </span>
                    </div>
                  </div>
                </div>
                {/* Single Item */}
                <div className='item'>
                  <div className='item-box'>
                    <div className='smart-tes-author'>
                      <div className='st-author-box'>
                        <div className='st-author-thumb'>
                          <div className='quotes bg-danger'>
                            <i className='fa-solid fa-quote-left' />
                          </div>
                          <img
                            src='https://via.placeholder.com/800x800'
                            className='img-fluid'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                    <div className='smart-tes-content'>
                      <p>
                        Cicero famously orated against his political opponent
                        Lucius Sergius Catilina. Occasionally the first Oration
                        against Catiline is taken specimens.
                      </p>
                    </div>
                    <div className='st-author-info'>
                      <h4 className='st-author-title'>Shilpa Shethy</h4>
                      <span className='st-author-subtitle'>CEO Of Zapple</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Smart Testimonials End ================================== */}
      {/* ================================= Blog Grid ================================== */}
      <section>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-10 text-center'>
              <div className='sec-heading center'>
                <h2>Latest Updates By AV Realties</h2>
                <p>
                  Stay informed with the latest news and updates from AV
                  REALTIES. Discover the newest properties, market trends, and
                  insights from the forefront of real estate
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center g-4'>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <Link to='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    30 july 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <Link to='/blog-detail'>
                      Why people choose listio for own properties
                    </Link>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <Link to='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </Link>
                </div>
              </div>
            </div>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <Link to='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    10 August 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <Link to='/blog-detail'>
                      List of benifits and impressive AV REALTIES services
                    </Link>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <Link to='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </Link>
                </div>
              </div>
            </div>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <Link to='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    30 Sep 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <Link to='/blog-detail'>
                      What people says about listio properties
                    </Link>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <Link to='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================== Blog Grid End =============================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='bg-light'>
        <div className='container'>
          <div className='row align-items-center justify-content-center gx-5 gy-5'>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/booking.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/columbia.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/Payoneer.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/Paypal.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/razorpay.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/microsoft.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/trivago.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/visa.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
            <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
              <div className='explor-thumb'>
                <img
                  src='assets/img/partners/columbia.png'
                  className='img-fluid'
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='row align-items-center justify-content-center'>
            <div className='col-xl-7 col-lg-11'>
              <div className='call-to-act-wrap text-center'>
                <div className='call-to-act-head mb-2'>
                  <h2 className='fs-1 mb-3 lh-sm'>
                    Subscribe &amp;
                    <br />
                    get special discount
                  </h2>
                  <span>
                    Subscribe now and unlock exclusive discounts on your
                    property ventures. Join our community to receive special
                    offers and savings directly in your inbox.
                  </span>
                </div>
              </div>
              <div className='call-to-act-form'>
                <form className='newsletter-boxes p-2'>
                  <div className='row m-0 g-0'>
                    <div className='col-xl-10 col-9'>
                      <input
                        type='text'
                        className='form-control border-0'
                        placeholder='Subscribe Your Email...'
                      />
                    </div>
                    <div className='col-xl-2 col-3'>
                      <button
                        type='submit'
                        className='btn btn-primary rounded-pill full-width'
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
