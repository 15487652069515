import React from 'react';
import { Link } from 'react-router-dom';

export default function Faq() {
  return (
    <div>
      {/* ============================ Page Title Start================================== */}
      <section
        className='image-cover faq-sec text-center'
        style={{
          background: 'url(https://via.placeholder.com/1920x950) no-repeat',
        }}
        data-overlay={6}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 position-relative z-1'>
              <h1 className='text-light'>Frequently Asked Questions</h1>
              <div className='faq-search'>
                <form>
                  <input
                    name='search'
                    className='form-control'
                    placeholder='Search Your Query...'
                  />
                  <button type='submit'>
                    <i className='fa-solid fa-magnifying-glass' />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Page Title End ================================== */}
      {/* ================= Our Mission ================= */}
      <section className='bg-light'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='contact-box'>
                <i className='fa-solid fa-basket-shopping text-primary' />
                <h4>Contact Sales</h4>
                <p>sales@rikadahelp.co.uk</p>
                <span>+01 215 245 6258</span>
              </div>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='contact-box'>
                <i className='fa-solid fa-user-tie text-primary' />
                <h4>Contact Sales</h4>
                <p>sales@rikadahelp.co.uk</p>
                <span>+01 215 245 6258</span>
              </div>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='contact-box'>
                <i className='fa-solid fa-comments text-primary' />
                <h4>Start Live Chat</h4>
                <span>+01 215 245 6258</span>
                <span className='live-chat'>Live Chat</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-10 col-md-12 col-sm-12'>
              <div className='block-header border-0 m-0'>
                <ul
                  className='nav nav-pills mb-3'
                  id='pills-tab'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link active'
                      id='general-tab'
                      data-bs-toggle='pill'
                      href='#general'
                      role='tab'
                      aria-controls='general'
                      aria-selected='true'
                    >
                      General
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link'
                      id='payment-tab'
                      data-bs-toggle='pill'
                      href='#payment'
                      role='tab'
                      aria-controls='payment'
                      aria-selected='false'
                    >
                      Payment
                    </a>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link'
                      id='upgrade-tab'
                      data-bs-toggle='pill'
                      href='#upgrade'
                      role='tab'
                      aria-controls='upgrade'
                      aria-selected='false'
                    >
                      Upgrade
                    </a>
                  </li>
                </ul>
              </div>
              <div className='tab-content' id='pills-tabContent'>
                {/* general Query */}
                <div
                  className='tab-pane fade show active'
                  id='general'
                  role='tabpanel'
                  aria-labelledby='general-tab'
                >
                  <div className='accordion border-0' id='generalac'>
                    <div className='card mb-2'>
                      <div className='card-header' id='headingOne'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            How To Install Rikada Theme?
                          </button>
                        </h2>
                      </div>
                      <div
                        id='collapseOne'
                        className='collapse show'
                        aria-labelledby='headingOne'
                        data-bs-parent='#generalac'
                      >
                        <div className='card-body'>
                          <p className='ac-para'>
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably have not heard of them accusamus labore
                            sustainable VHS.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card mb-2'>
                      <div className='card-header' id='headingTwo'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            What is main Requirements For Rikada?
                          </button>
                        </h2>
                      </div>
                      <div
                        id='collapseTwo'
                        className='collapse'
                        aria-labelledby='headingTwo'
                        data-bs-parent='#generalac'
                      >
                        <div className='card-body'>
                          <p className='ac-para'>
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably have not heard of them accusamus labore
                            sustainable VHS.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card mb-2'>
                      <div className='card-header' id='headingThree'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            Why Choose Rikada Theme?
                          </button>
                        </h2>
                      </div>
                      <div
                        id='collapseThree'
                        className='collapse'
                        aria-labelledby='headingThree'
                        data-bs-parent='#generalac'
                      >
                        <div className='card-body'>
                          <p className='ac-para'>
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably have not heard of them accusamus labore
                            sustainable VHS.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* general Query */}
                <div
                  className='tab-pane fade'
                  id='payment'
                  role='tabpanel'
                  aria-labelledby='payment-tab'
                >
                  <div className='accordion border-0' id='paymentac'>
                    <div className='card mb-2'>
                      <div className='card-header' id='headingOne1'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#paymentcollapse'
                            aria-expanded='true'
                            aria-controls='paymentcollapse'
                          >
                            May I Request For Refund?
                          </button>
                        </h2>
                      </div>
                      <div
                        id='paymentcollapse'
                        className='collapse show'
                        aria-labelledby='headingOne1'
                        data-bs-parent='#paymentac'
                      >
                        <div className='card-body'>
                          <p className='ac-para'>
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably have not heard of them accusamus labore
                            sustainable VHS.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card mb-2'>
                      <div className='card-header' id='headingTwo1'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#paymentcollapseTwo'
                            aria-expanded='false'
                            aria-controls='paymentcollapseTwo'
                          >
                            May I Get Any Offer in Future?
                          </button>
                        </h2>
                      </div>
                      <div
                        id='paymentcollapseTwo'
                        className='collapse'
                        aria-labelledby='headingTwo1'
                        data-bs-parent='#paymentac'
                      >
                        <div className='card-body'>
                          <p className='ac-para'>
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably have not heard of them accusamus labore
                            sustainable VHS.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card mb-2'>
                      <div className='card-header' id='headingThree1'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#paymentcollapseThree'
                            aria-expanded='false'
                            aria-controls='paymentcollapseThree'
                          >
                            How Much Time It will Take For refund?
                          </button>
                        </h2>
                      </div>
                      <div
                        id='paymentcollapseThree'
                        className='collapse'
                        aria-labelledby='headingThree1'
                        data-bs-parent='#paymentac'
                      >
                        <div className='card-body'>
                          <p className='ac-para'>
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably have not heard of them accusamus labore
                            sustainable VHS.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* general Query */}
                <div
                  className='tab-pane fade'
                  id='upgrade'
                  role='tabpanel'
                  aria-labelledby='upgrade-tab'
                >
                  <div className='accordion border-0' id='upgradeac'>
                    <div className='card mb-2'>
                      <div className='card-header' id='headingOne2'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#upgradecollapseOne'
                            aria-expanded='true'
                            aria-controls='upgradecollapseOne'
                          >
                            How To Upgrade Rikada Theme?
                          </button>
                        </h2>
                      </div>
                      <div
                        id='upgradecollapseOne'
                        className='collapse show'
                        aria-labelledby='headingOne2'
                        data-bs-parent='#upgradeac'
                      >
                        <div className='card-body'>
                          <p className='ac-para'>
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably have not heard of them accusamus labore
                            sustainable VHS.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card mb-2'>
                      <div className='card-header' id='headingTwo2'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#upgradecollapseTwo'
                            aria-expanded='false'
                            aria-controls='upgradecollapseTwo'
                          >
                            Rikada available for WordPress Version?
                          </button>
                        </h2>
                      </div>
                      <div
                        id='upgradecollapseTwo'
                        className='collapse'
                        aria-labelledby='headingTwo2'
                        data-bs-parent='#upgradeac'
                      >
                        <div className='card-body'>
                          <p className='ac-para'>
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably have not heard of them accusamus labore
                            sustainable VHS.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='card mb-2'>
                      <div className='card-header' id='headingThree2'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#upgradecollapseThree'
                            aria-expanded='false'
                            aria-controls='upgradecollapseThree'
                          >
                            Why We need to upgrade Rikada?
                          </button>
                        </h2>
                      </div>
                      <div
                        id='upgradecollapseThree'
                        className='collapse'
                        aria-labelledby='headingThree2'
                        data-bs-parent='#upgradeac'
                      >
                        <div className='card-body'>
                          <p className='ac-para'>
                            Anim pariatur cliche reprehenderit, enim eiusmod
                            high life accusamus terry richardson ad squid. 3
                            wolf moon officia aute, non cupidatat skateboard
                            dolor brunch. Food truck quinoa nesciunt laborum
                            eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put
                            a bird on it squid single-origin coffee nulla
                            assumenda shoreditch et. Nihil anim keffiyeh
                            helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident. Ad vegan excepteur
                            butcher vice lomo. Leggings occaecat craft beer
                            farm-to-table, raw denim aesthetic synth nesciunt
                            you probably have not heard of them accusamus labore
                            sustainable VHS.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ================= Our Mission ================= */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
