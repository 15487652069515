import React from 'react';
import { Link } from 'react-router-dom';

export default function GridLayoutWithSidebar() {
  return (
    <div>
      {/* ============================ Page Title Start================================== */}
      <div className='page-title'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <h2 className='ipt-title'>Grid Layout With Sidebar</h2>
              <span className='ipn-subtitle'>Property Grid With Sidebar</span>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Page Title End ================================== */}
      {/* ============================ All Property ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='filter_search_opt'>
                <Link
                  to='/'
                  className='btn btn-dark full-width mb-4'
                  onClick='openFilterSearch()'
                >
                  <span className='svg-icon text-light svg-icon-2hx me-2'>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  Open Filter Option
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            {/* property Sidebar */}
            <div className='col-lg-4 col-md-12 col-sm-12'>
              <div
                className='simple-sidebar sm-sidebar'
                id='filter_search'
                style={{ left: 0 }}
              >
                <div className='search-sidebar_header'>
                  <h4 className='ssh_heading'>Close Filter</h4>
                  <button
                    type='button'
                    onClick='closeFilterSearch()'
                    className='w3-bar-item w3-button w3-large'
                  >
                    <i className='fa-regular fa-circle-xmark fs-5 text-muted-2' />
                  </button>
                </div>
                {/* Find New Property */}
                <div className='sidebar-widgets'>
                  <div className='search-inner p-0'>
                    <div className='filter-search-box'>
                      <div className='form-group'>
                        <div className='position-relative'>
                          <input
                            type='text'
                            className='form-control rounded-3 ps-5'
                            placeholder='Search by space name…'
                          />
                          <div className='position-absolute top-50 start-0 translate-middle-y ms-2'>
                            <span className='svg-icon text-primary svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  opacity='0.5'
                                  x='17.0365'
                                  y='15.1223'
                                  width='8.15546'
                                  height={2}
                                  rx={1}
                                  transform='rotate(45 17.0365 15.1223)'
                                  fill='currentColor'
                                />
                                <path
                                  d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='position-relative d-flex flex-xl-row flex-column align-items-center'>
                      <div className='verifyd-prt-block flex-fill full-width my-1 me-1'>
                        <div className='d-flex align-items-center justify-content-center justify-content-between border rounded-3 px-2 py-3'>
                          <div className='eliok-cliops d-flex align-items-center'>
                            <span className='svg-icon text-success svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                            <span className='text-muted-2 fw-medium ms-1'>
                              Verified
                            </span>
                          </div>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              role='switch'
                              id='flexSwitchCheckChecked'
                              defaultChecked
                            />
                            <label
                              className='form-check-label'
                              htmlFor='flexSwitchCheckChecked'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='super-agt-block flex-fill full-width my-1 ms-1'>
                        <div className='d-flex align-items-center justify-content-center justify-content-between border rounded-3 px-2 py-3'>
                          <div className='eliok-cliops d-flex align-items-center'>
                            <span className='svg-icon text-warning svg-icon-2hx'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'
                              >
                                <path
                                  d='M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z'
                                  fill='white'
                                />
                              </svg>
                            </span>
                            <span className='text-muted-2 fw-medium ms-1'>
                              SuperAgent
                            </span>
                          </div>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              role='switch'
                              id='flexSwitchCheckChecked'
                              defaultChecked
                            />
                            <label
                              className='form-check-label'
                              htmlFor='flexSwitchCheckChecked'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='filter_wraps'>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#where'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Where<span className='selected'>Chicago</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='where'
                          data-parent='#where'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='b1'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b1'
                                        className='form-check-label'
                                      >
                                        Atlanta
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b2'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b2'
                                        className='form-check-label'
                                      >
                                        Austin
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b3'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b3'
                                        className='form-check-label'
                                      >
                                        Boston
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b4'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='b4'
                                        className='form-check-label'
                                      >
                                        Chicago
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b5'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b5'
                                        className='form-check-label'
                                      >
                                        Dallas
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b6'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b6'
                                        className='form-check-label'
                                      >
                                        Denver
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b7'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b7'
                                        className='form-check-label'
                                      >
                                        Houston
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b8'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b8'
                                        className='form-check-label'
                                      >
                                        Jacksonville
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='b9'
                                        className='form-check-input'
                                        name='where'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='b9'
                                        className='form-check-label'
                                      >
                                        Los Angeles
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#fptype'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Property Types
                              <span className='selected'>Apartment</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='fptype'
                          data-parent='#fptype'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='c1'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c1'
                                        className='form-check-label'
                                      >
                                        House
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c2'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c2'
                                        className='form-check-label'
                                      >
                                        Office Desk
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c3'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c3'
                                        className='form-check-label'
                                      >
                                        Villa
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c4'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='c4'
                                        className='form-check-label'
                                      >
                                        Apartment
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c5'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c5'
                                        className='form-check-label'
                                      >
                                        Condo
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c6'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c6'
                                        className='form-check-label'
                                      >
                                        Denver
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='c7'
                                        className='form-check-input'
                                        name='ptype'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='c7'
                                        className='form-check-label'
                                      >
                                        Studio
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#fbedrooms'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Bedrooms<span className='selected'>2 Beds</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='fbedrooms'
                          data-parent='#fbedrooms'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='a1'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='a1'
                                        className='form-check-label'
                                      >
                                        01 Bedrooms
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='a2'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='a2'
                                        className='form-check-label'
                                      >
                                        02 Bedrooms
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='a3'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='a3'
                                        className='form-check-label'
                                      >
                                        03 Bedrooms
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='a4'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='a4'
                                        className='form-check-label'
                                      >
                                        04 Bedrooms
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='a5'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='a5'
                                        className='form-check-label'
                                      >
                                        05 Bedrooms
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='a6'
                                        className='form-check-input'
                                        name='bed'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='a6'
                                        className='form-check-label'
                                      >
                                        06+ Bedrooms
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#fprice'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Price Range
                              <span className='selected'>
                                $10,000 - $15,000
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='fprice'
                          data-parent='#fprice'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='e1'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e1'
                                        className='form-check-label'
                                      >
                                        Less Then $10,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e2'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e2'
                                        className='form-check-label'
                                      >
                                        $10,000 - $15,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e3'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e3'
                                        className='form-check-label'
                                      >
                                        $12,000 - $25,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e4'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='e4'
                                        className='form-check-label'
                                      >
                                        $30,000 - $35,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e5'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e5'
                                        className='form-check-label'
                                      >
                                        $40,000 - $45,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e6'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e6'
                                        className='form-check-label'
                                      >
                                        $50,000 - $55,000
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='e7'
                                        className='form-check-input'
                                        name='prices'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='e7'
                                        className='form-check-label'
                                      >
                                        $60,000 - $65,000
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#mood'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Mood<span className='selected'>Any Mood</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='mood'
                          data-parent='#mood'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='f1'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f1'
                                        className='form-check-label'
                                      >
                                        Any Mood
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f2'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f2'
                                        className='form-check-label'
                                      >
                                        Professional
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f3'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f3'
                                        className='form-check-label'
                                      >
                                        Essentials
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f4'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='f4'
                                        className='form-check-label'
                                      >
                                        Unique
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f5'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f5'
                                        className='form-check-label'
                                      >
                                        Lively
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f6'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f6'
                                        className='form-check-label'
                                      >
                                        Luxe
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='f7'
                                        className='form-check-input'
                                        name='moods'
                                        type='radio'
                                      />
                                      <label
                                        htmlFor='f7'
                                        className='form-check-label'
                                      >
                                        Luxe
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Single Search */}
                      <div className='single_search_boxed'>
                        <div className='widget-boxed-header'>
                          <h4>
                            <a
                              href='#ameneties'
                              data-bs-toggle='collapse'
                              aria-expanded='false'
                              role='button'
                              className='collapsed'
                            >
                              Ameneties
                              <span className='selected'>ADA Compliant</span>
                            </a>
                          </h4>
                        </div>
                        <div
                          className='widget-boxed-body collapse'
                          id='ameneties'
                          data-parent='#ameneties'
                        >
                          <div className='side-list no-border'>
                            {/* Single Filter Card */}
                            <div className='single_filter_card'>
                              <div className='card-body pt-0'>
                                <div className='inner_widget_link'>
                                  <ul className='no-ul-list filter-list'>
                                    <li className='form-check'>
                                      <input
                                        id='g1'
                                        className='form-check-input'
                                        name='ADA'
                                        type='checkbox'
                                        defaultChecked
                                      />
                                      <label
                                        htmlFor='g1'
                                        className='form-check-label'
                                      >
                                        ADA Compliant
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g2'
                                        className='form-check-input'
                                        name='Parking'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g2'
                                        className='form-check-label'
                                      >
                                        Parking Options
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g3'
                                        className='form-check-input'
                                        name='Coffee'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g3'
                                        className='form-check-label'
                                      >
                                        Coffee Provided
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g4'
                                        className='form-check-input'
                                        name='Mother'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g4'
                                        className='form-check-label'
                                      >
                                        Mothers Room
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g5'
                                        className='form-check-input'
                                        name='Outdoor'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g5'
                                        className='form-check-label'
                                      >
                                        Outdoor Space
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g6'
                                        className='form-check-input'
                                        name='Pet'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g6'
                                        className='form-check-label'
                                      >
                                        Pet Friendly
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g7'
                                        className='form-check-input'
                                        name='Beauty'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g7'
                                        className='form-check-label'
                                      >
                                        Beauty &amp; Message
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g8'
                                        className='form-check-input'
                                        name='Bike'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g8'
                                        className='form-check-label'
                                      >
                                        Bike Parking
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g9'
                                        className='form-check-input'
                                        name='Phone'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g9'
                                        className='form-check-label'
                                      >
                                        Phone Line
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g11'
                                        className='form-check-input'
                                        name='Private'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g11'
                                        className='form-check-label'
                                      >
                                        Private Areas
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g12'
                                        className='form-check-input'
                                        name='Free'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g12'
                                        className='form-check-label'
                                      >
                                        Free WiFi
                                      </label>
                                    </li>
                                    <li className='form-check'>
                                      <input
                                        id='g13'
                                        className='form-check-input'
                                        name='Swiming'
                                        type='checkbox'
                                      />
                                      <label
                                        htmlFor='g13'
                                        className='form-check-label'
                                      >
                                        Swiming Pool
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-group filter_button'>
                      <button
                        type='submit'
                        className='btn btn btn-primary rounded full-width'
                      >
                        22 Results Show
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Sidebar End */}
            </div>
            <div className='col-lg-8 col-md-12 col-sm-12'>
              <div className='row justify-content-center'>
                <div className='col-lg-12 col-md-12'>
                  <div className='item-shorting-box'>
                    <div className='item-shorting clearfix'>
                      <div className='left-column pull-left'>
                        <h4 className='fs-6 m-0'>Found 1-10 of 142 Results</h4>
                      </div>
                    </div>
                    <div className='item-shorting-box-right'>
                      <div className='shorting-by'>
                        <select id='shorty' className='form-control'>
                          <option value>&nbsp;</option>
                          <option value={1}>Low Price</option>
                          <option value={2}>High Price</option>
                          <option value={3}>Most Popular</option>
                        </select>
                      </div>
                      <ul className='shorting-list'>
                        <li>
                          <Link
                            to='/grid-layout-with-sidebar'
                            className='active w-12 h-12'
                          >
                            <span className='svg-icon text-seegreen svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x={2}
                                  y={2}
                                  width={9}
                                  height={9}
                                  rx={2}
                                  fill='currentColor'
                                />
                                <rect
                                  opacity='0.3'
                                  x={13}
                                  y={2}
                                  width={9}
                                  height={9}
                                  rx={2}
                                  fill='currentColor'
                                />
                                <rect
                                  opacity='0.3'
                                  x={13}
                                  y={13}
                                  width={9}
                                  height={9}
                                  rx={2}
                                  fill='currentColor'
                                />
                                <rect
                                  opacity='0.3'
                                  x={2}
                                  y={13}
                                  width={9}
                                  height={9}
                                  rx={2}
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/list-layout-with-sidebar'
                            className='w-12 h-12'
                          >
                            <span className='svg-icon text-muted-2 svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M14 10V20C14 20.6 13.6 21 13 21H10C9.4 21 9 20.6 9 20V10C9 9.4 9.4 9 10 9H13C13.6 9 14 9.4 14 10ZM20 9H17C16.4 9 16 9.4 16 10V20C16 20.6 16.4 21 17 21H20C20.6 21 21 20.6 21 20V10C21 9.4 20.6 9 20 9Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M7 10V20C7 20.6 6.6 21 6 21H3C2.4 21 2 20.6 2 20V10C2 9.4 2.4 9 3 9H6C6.6 9 7 9.4 7 10ZM21 6V3C21 2.4 20.6 2 20 2H3C2.4 2 2 2.4 2 3V6C2 6.6 2.4 7 3 7H20C20.6 7 21 6.6 21 6Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row justify-content-center g-4'>
                {/* Single Property */}
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                  <div className='property-listing card border-0 rounded-3'>
                    <div className='listing-img-wrapper p-3'>
                      <div className='list-img-slide position-relative'>
                        <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                          <div className='label bg-success text-light d-inline-flex align-items-center justify-content-center'>
                            <span className='svg-icon text-light svg-icon-2hx me-1'>
                              <svg
                                width={14}
                                height={14}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                            Verified
                          </div>
                        </div>
                        <div className='click rounded-3 overflow-hidden mb-0'>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='listing-caption-wrapper px-3'>
                      <div className='listing-detail-wrapper'>
                        <div className='listing-short-detail-wrap'>
                          <div className='listing-short-detail'>
                            <div className='d-flex align-items-center'>
                              <span className='label bg-light-success text-success prt-type me-2'>
                                For Rent
                              </span>
                              <span className='label bg-light-purple text-purple property-cats'>
                                Apartment
                              </span>
                            </div>
                            <h4 className='listing-name fw-semibold fs-5 mb-1'>
                              <Link to='/single-property-1'>
                                The Green Canton Chrysler
                              </Link>
                            </h4>
                            <div className='prt-location text-muted-2'>
                              <span className='svg-icon svg-icon-2hx'>
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    opacity='0.3'
                                    d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                              210 Zirak Road, Canada
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-detail-footer d-flex align-items-center justify-content-between py-4'>
                        <div className='listing-short-detail-flex'>
                          <h6 className='listing-card-info-price m-0'>
                            $80,000
                          </h6>
                        </div>
                        <div className='footer-flex'>
                          <Link to='/property-detail' className='prt-view'>
                            <span className='svg-icon text-primary svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z'
                                  fill='currentColor'
                                />
                                <path
                                  opacity='0.3'
                                  d='M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Single Property */}
                {/* Single Property */}
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                  <div className='property-listing card border-0 rounded-3'>
                    <div className='listing-img-wrapper p-3'>
                      <div className='list-img-slide position-relative'>
                        <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                          <div className='label bg-purple text-light d-inline-flex align-items-center justify-content-center'>
                            <span className='svg-icon text-light svg-icon-2hx me-1'>
                              <svg
                                width={14}
                                height={14}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                            SuperAgent
                          </div>
                        </div>
                        <div className='click rounded-3 overflow-hidden mb-0'>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='listing-caption-wrapper px-3'>
                      <div className='listing-detail-wrapper'>
                        <div className='listing-short-detail-wrap'>
                          <div className='listing-short-detail'>
                            <div className='d-flex align-items-center'>
                              <span className='label bg-light-danger text-danger prt-type me-2'>
                                For Sell
                              </span>
                              <span className='label bg-light-purple text-purple property-cats'>
                                House
                              </span>
                            </div>
                            <h4 className='listing-name fw-semibold fs-5 mb-1'>
                              <Link to='/single-property-1'>
                                Purple Flatiron House
                              </Link>
                            </h4>
                            <div className='prt-location text-muted-2'>
                              <span className='svg-icon svg-icon-2hx'>
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    opacity='0.3'
                                    d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                              210 Zirak Road, Canada
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-detail-footer d-flex align-items-center justify-content-between py-4'>
                        <div className='listing-short-detail-flex'>
                          <h6 className='listing-card-info-price m-0'>
                            $67,000
                          </h6>
                        </div>
                        <div className='footer-flex'>
                          <Link to='/property-detail' className='prt-view'>
                            <span className='svg-icon text-primary svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z'
                                  fill='currentColor'
                                />
                                <path
                                  opacity='0.3'
                                  d='M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Single Property */}
                {/* Single Property */}
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                  <div className='property-listing card border-0 rounded-3'>
                    <div className='listing-img-wrapper p-3'>
                      <div className='list-img-slide position-relative'>
                        <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                          <div className='label bg-success text-light d-inline-flex align-items-center justify-content-center'>
                            <span className='svg-icon text-light svg-icon-2hx me-1'>
                              <svg
                                width={14}
                                height={14}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                            Verified
                          </div>
                          <div className='label bg-danger text-light d-inline-flex align-items-center justify-content-center ms-1'>
                            <span className='svg-icon text-light svg-icon-2hx me-1'>
                              <svg
                                width={14}
                                height={14}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48884 12.8122 9.48884 9.48886C9.48884 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32556 3.42859 3.42857 7.29209 3.42857 12C3.42857 16.7079 7.32556 20.5714 12.0335 20.5714Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M13.0379 7.47998C13.8688 7.47998 14.5446 8.15585 14.5446 8.98668C14.5446 9.26428 14.7693 9.48891 15.0469 9.48891C15.3245 9.48891 15.5491 9.26428 15.5491 8.98668C15.5491 8.15585 16.225 7.47998 17.0558 7.47998C17.3334 7.47998 17.558 7.25535 17.558 6.97775C17.558 6.70015 17.3334 6.47552 17.0558 6.47552C16.225 6.47552 15.5491 5.76616 15.5491 4.93534C15.5491 4.65774 15.3245 4.43311 15.0469 4.43311C14.7693 4.43311 14.5446 4.65774 14.5446 4.93534C14.5446 5.76616 13.8688 6.47552 13.0379 6.47552C12.7603 6.47552 12.5357 6.70015 12.5357 6.97775C12.5357 7.25535 12.7603 7.47998 13.0379 7.47998Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                            New
                          </div>
                        </div>
                        <div className='click rounded-3 overflow-hidden mb-0'>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='listing-caption-wrapper px-3'>
                      <div className='listing-detail-wrapper'>
                        <div className='listing-short-detail-wrap'>
                          <div className='listing-short-detail'>
                            <div className='d-flex align-items-center'>
                              <span className='label bg-light-success text-success prt-type me-2'>
                                For Rent
                              </span>
                              <span className='label bg-light-purple text-purple property-cats'>
                                Building
                              </span>
                            </div>
                            <h4 className='listing-name fw-semibold fs-5 mb-1'>
                              <Link to='/single-property-1'>
                                Rustic Reunion Tower
                              </Link>
                            </h4>
                            <div className='prt-location text-muted-2'>
                              <span className='svg-icon svg-icon-2hx'>
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    opacity='0.3'
                                    d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                              210 Zirak Road, Canada
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-detail-footer d-flex align-items-center justify-content-between py-4'>
                        <div className='listing-short-detail-flex'>
                          <h6 className='listing-card-info-price m-0'>
                            $92,500
                          </h6>
                        </div>
                        <div className='footer-flex'>
                          <Link to='/property-detail' className='prt-view'>
                            <span className='svg-icon text-primary svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z'
                                  fill='currentColor'
                                />
                                <path
                                  opacity='0.3'
                                  d='M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Single Property */}
                {/* Single Property */}
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                  <div className='property-listing card border-0 rounded-3'>
                    <div className='listing-img-wrapper p-3'>
                      <div className='list-img-slide position-relative'>
                        <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                          <div className='label bg-purple text-light d-inline-flex align-items-center justify-content-center'>
                            <span className='svg-icon text-light svg-icon-2hx me-1'>
                              <svg
                                width={14}
                                height={14}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                            SuperAgent
                          </div>
                        </div>
                        <div className='click rounded-3 overflow-hidden mb-0'>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='listing-caption-wrapper px-3'>
                      <div className='listing-detail-wrapper'>
                        <div className='listing-short-detail-wrap'>
                          <div className='listing-short-detail'>
                            <div className='d-flex align-items-center'>
                              <span className='label bg-light-danger text-danger prt-type me-2'>
                                For Sell
                              </span>
                              <span className='label bg-light-purple text-purple property-cats'>
                                Condos
                              </span>
                            </div>
                            <h4 className='listing-name fw-semibold fs-5 mb-1'>
                              <Link to='/single-property-1'>
                                The Red Freedom Tower
                              </Link>
                            </h4>
                            <div className='prt-location text-muted-2'>
                              <span className='svg-icon svg-icon-2hx'>
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    opacity='0.3'
                                    d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                              210 Zirak Road, Canada
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-detail-footer d-flex align-items-center justify-content-between py-4'>
                        <div className='listing-short-detail-flex'>
                          <h6 className='listing-card-info-price m-0'>
                            $89,000
                          </h6>
                        </div>
                        <div className='footer-flex'>
                          <Link to='/property-detail' className='prt-view'>
                            <span className='svg-icon text-primary svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z'
                                  fill='currentColor'
                                />
                                <path
                                  opacity='0.3'
                                  d='M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Single Property */}
                {/* Single Property */}
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                  <div className='property-listing card border-0 rounded-3'>
                    <div className='listing-img-wrapper p-3'>
                      <div className='list-img-slide position-relative'>
                        <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                          <div className='label bg-success text-light d-inline-flex align-items-center justify-content-center'>
                            <span className='svg-icon text-light svg-icon-2hx me-1'>
                              <svg
                                width={14}
                                height={14}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                            Verified
                          </div>
                          <div className='label bg-danger text-light d-inline-flex align-items-center justify-content-center ms-1'>
                            <span className='svg-icon text-light svg-icon-2hx me-1'>
                              <svg
                                width={14}
                                height={14}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48884 12.8122 9.48884 9.48886C9.48884 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32556 3.42859 3.42857 7.29209 3.42857 12C3.42857 16.7079 7.32556 20.5714 12.0335 20.5714Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M13.0379 7.47998C13.8688 7.47998 14.5446 8.15585 14.5446 8.98668C14.5446 9.26428 14.7693 9.48891 15.0469 9.48891C15.3245 9.48891 15.5491 9.26428 15.5491 8.98668C15.5491 8.15585 16.225 7.47998 17.0558 7.47998C17.3334 7.47998 17.558 7.25535 17.558 6.97775C17.558 6.70015 17.3334 6.47552 17.0558 6.47552C16.225 6.47552 15.5491 5.76616 15.5491 4.93534C15.5491 4.65774 15.3245 4.43311 15.0469 4.43311C14.7693 4.43311 14.5446 4.65774 14.5446 4.93534C14.5446 5.76616 13.8688 6.47552 13.0379 6.47552C12.7603 6.47552 12.5357 6.70015 12.5357 6.97775C12.5357 7.25535 12.7603 7.47998 13.0379 7.47998Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                            New
                          </div>
                        </div>
                        <div className='click rounded-3 overflow-hidden mb-0'>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='listing-caption-wrapper px-3'>
                      <div className='listing-detail-wrapper'>
                        <div className='listing-short-detail-wrap'>
                          <div className='listing-short-detail'>
                            <div className='d-flex align-items-center'>
                              <span className='label bg-light-success text-success prt-type me-2'>
                                For Rent
                              </span>
                              <span className='label bg-light-purple text-purple property-cats'>
                                Villa
                              </span>
                            </div>
                            <h4 className='listing-name fw-semibold fs-5 mb-1'>
                              <Link to='/single-property-1'>
                                The Donald Dwelling
                              </Link>
                            </h4>
                            <div className='prt-location text-muted-2'>
                              <span className='svg-icon svg-icon-2hx'>
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    opacity='0.3'
                                    d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                              210 Zirak Road, Canada
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-detail-footer d-flex align-items-center justify-content-between py-4'>
                        <div className='listing-short-detail-flex'>
                          <h6 className='listing-card-info-price m-0'>
                            $88,000
                          </h6>
                        </div>
                        <div className='footer-flex'>
                          <Link to='/property-detail' className='prt-view'>
                            <span className='svg-icon text-primary svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z'
                                  fill='currentColor'
                                />
                                <path
                                  opacity='0.3'
                                  d='M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Single Property */}
                {/* Single Property */}
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                  <div className='property-listing card border-0 rounded-3'>
                    <div className='listing-img-wrapper p-3'>
                      <div className='list-img-slide position-relative'>
                        <div className='position-absolute top-0 left-0 ms-3 mt-3 z-1'>
                          <div className='label bg-purple text-light d-inline-flex align-items-center justify-content-center'>
                            <span className='svg-icon text-light svg-icon-2hx me-1'>
                              <svg
                                width={14}
                                height={14}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  opacity='0.3'
                                  d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z'
                                  fill='currentColor'
                                />
                                <path
                                  d='M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                            SuperAgent
                          </div>
                        </div>
                        <div className='click rounded-3 overflow-hidden mb-0'>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                          <div>
                            <Link to='/single-property-1'>
                              <img
                                src='https://via.placeholder.com/1200x850'
                                className='img-fluid'
                                alt=''
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='listing-caption-wrapper px-3'>
                      <div className='listing-detail-wrapper'>
                        <div className='listing-short-detail-wrap'>
                          <div className='listing-short-detail'>
                            <div className='d-flex align-items-center'>
                              <span className='label bg-light-danger text-danger prt-type me-2'>
                                For Sell
                              </span>
                              <span className='label bg-light-purple text-purple property-cats'>
                                Building
                              </span>
                            </div>
                            <h4 className='listing-name fw-semibold fs-5 mb-1'>
                              <Link to='/single-property-1'>
                                Red Tiny Hearst Castle
                              </Link>
                            </h4>
                            <div className='prt-location text-muted-2'>
                              <span className='svg-icon svg-icon-2hx'>
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    opacity='0.3'
                                    d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                                    fill='currentColor'
                                  />
                                  <path
                                    d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                              210 Zirak Road, Canada
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='price-features-wrapper'>
                        <div className='list-fx-features d-flex align-items-center justify-content-between'>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-building-shield fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3BHK</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-bed fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>3 Beds</span>
                          </div>
                          <div className='listing-card d-flex align-items-center'>
                            <div className='square--25 text-muted-2 fs-sm circle gray-simple me-1'>
                              <i className='fa-solid fa-clone fs-xs' />
                            </div>
                            <span className='text-muted-2 fs-sm'>
                              1800 SQFT
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='listing-detail-footer d-flex align-items-center justify-content-between py-4'>
                        <div className='listing-short-detail-flex'>
                          <h6 className='listing-card-info-price m-0'>
                            $10,50000
                          </h6>
                        </div>
                        <div className='footer-flex'>
                          <Link to='/property-detail' className='prt-view'>
                            <span className='svg-icon text-primary svg-icon-2hx'>
                              <svg
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z'
                                  fill='currentColor'
                                />
                                <path
                                  opacity='0.3'
                                  d='M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z'
                                  fill='currentColor'
                                />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Single Property */}
              </div>
              {/* Pagination */}
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <ul className='pagination p-center'>
                    <li className='page-item'>
                      <Link className='page-link' to='/' aria-label='Previous'>
                        <i className='fa-solid fa-arrow-left-long' />
                        <span className='sr-only'>Previous</span>
                      </Link>
                    </li>
                    <li className='page-item'>
                      <Link className='page-link' to='/'>
                        1
                      </Link>
                    </li>
                    <li className='page-item'>
                      <Link className='page-link' to='/'>
                        2
                      </Link>
                    </li>
                    <li className='page-item active'>
                      <Link className='page-link' to='/'>
                        3
                      </Link>
                    </li>
                    <li className='page-item'>
                      <Link className='page-link' to='/'>
                        ...
                      </Link>
                    </li>
                    <li className='page-item'>
                      <Link className='page-link' to='/'>
                        18
                      </Link>
                    </li>
                    <li className='page-item'>
                      <Link className='page-link' to='/' aria-label='Next'>
                        <i className='fa-solid fa-arrow-right-long' />
                        <span className='sr-only'>Next</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ All Property ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/create-account' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
