import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import HeaderOffCanvas from './CompareAndSelectProperty';
import Login from '../components/Login';

export default function Layout() {
  return (
    <div id='main-wrapper'>
      <div className='clearfix' />
      <HeaderOffCanvas />
      <Header />
      <Outlet />
      <Footer />
      <Login />
    </div>
  );
}
