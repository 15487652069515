import React from 'react';
import { Link } from 'react-router-dom';

export default function Blog() {
  return (
    <div>
      {/* ============================ Page Title Start================================== */}
      <div className='page-title'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <h2 className='ipt-title'>Our Articles</h2>
              <span className='ipn-subtitle'>
                See Our Latest Articles &amp; News
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Page Title End ================================== */}
      {/* ============================ Blog List Start ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          <div className='row'>
            <div className='col text-center'>
              <div className='sec-heading center'>
                <h2>Latest News</h2>
                <p>
                  We post regulary most powerful articles for help and support.
                </p>
              </div>
            </div>
          </div>
          {/* row Start */}
          <div className='row justify-content-center g-4'>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <Link to='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    30 july 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <Link to='/blog-detail'>
                      Why people choose listio for own properties
                    </Link>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <Link to='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </Link>
                </div>
              </div>
            </div>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <Link to='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    10 August 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <Link to='/blog-detail'>
                      List of benifits and impressive AV REALTIES services
                    </Link>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <Link to='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </Link>
                </div>
              </div>
            </div>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <Link to='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    30 Sep 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <Link to='/blog-detail'>
                      What people says about listio properties
                    </Link>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <Link to='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </Link>
                </div>
              </div>
            </div>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <Link to='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    30 july 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <Link to='/blog-detail'>
                      Why people choose listio for own properties
                    </Link>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <Link to='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </Link>
                </div>
              </div>
            </div>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <Link to='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    10 August 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <Link to='/blog-detail'>
                      List of benifits and impressive AV REALTIES services
                    </Link>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <Link to='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </Link>
                </div>
              </div>
            </div>
            {/* Single blog Grid */}
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='blog-wrap-grid h-100 shadow'>
                <div className='blog-thumb'>
                  <Link to='/blog-detail'>
                    <img
                      src='https://via.placeholder.com/1200x850'
                      className='img-fluid'
                      alt=''
                    />
                  </Link>
                </div>
                <div className='blog-info'>
                  <span className='post-date label bg-seegreen text-light'>
                    <i className='ti-calendar' />
                    30 Sep 2018
                  </span>
                </div>
                <div className='blog-body'>
                  <h4 className='bl-title'>
                    <Link to='/blog-detail'>
                      What people says about listio properties
                    </Link>
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore.
                  </p>
                  <Link to='/blog-detail' className='text-primary fw-medium'>
                    Continue
                    <i className='fa-solid fa-arrow-right ms-2' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* /row */}
          {/* Pagination */}
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <ul className='pagination p-center'>
                <li className='page-item'>
                  <Link className='page-link' to='/' aria-label='Previous'>
                    <i className='fa-solid fa-arrow-left-long' />
                    <span className='sr-only'>Previous</span>
                  </Link>
                </li>
                <li className='page-item'>
                  <Link className='page-link' to='/'>
                    1
                  </Link>
                </li>
                <li className='page-item'>
                  <Link className='page-link' to='/'>
                    2
                  </Link>
                </li>
                <li className='page-item active'>
                  <Link className='page-link' to='/'>
                    3
                  </Link>
                </li>
                <li className='page-item'>
                  <Link className='page-link' to='/'>
                    ...
                  </Link>
                </li>
                <li className='page-item'>
                  <Link className='page-link' to='/'>
                    18
                  </Link>
                </li>
                <li className='page-item'>
                  <Link className='page-link' to='/' aria-label='Next'>
                    <i className='fa-solid fa-arrow-right-long' />
                    <span className='sr-only'>Next</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Blog List End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
