import React from 'react';
import { Link } from 'react-router-dom';

export default function Checkout() {
  return (
    <div>
      {/* ============================ Page Title Start================================== */}
      <div className='page-title'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <h2 className='ipt-title'>Checkout</h2>
              <span className='ipn-subtitle'>Proceed For Payment</span>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Page Title End ================================== */}
      {/* ============================ Our Story Start ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          {/* row Start */}
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div
                className='alert bg-success text-light text-center'
                role='alert'
              >
                Hi Dear, Have you already an account?
                <Link
                  to='/'
                  className='text-warning'
                  data-bs-toggle='collapse'
                  data-bs-target='#login-frm'
                >
                  Please Login
                </Link>
              </div>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div id='login-frm' className='collapse mb-5'>
                <div className='row'>
                  <div className='col-lg-5 col-md-4 col-sm-6'>
                    <div className='form-group'>
                      <div className='input-with-icons'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Username'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-5 col-md-4 col-sm-6'>
                    <div className='form-group'>
                      <div className='input-with-icons'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='*******'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-2 col-md-4 col-sm-12'>
                    <div className='form-group'>
                      <button
                        type='submit'
                        className='btn btn-primary full-width'
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className='exclop-wrap d-flex align-items-center justify-content-between'>
                      <div className='exclop'>
                        <input
                          id='a-1'
                          className='form-check-input'
                          name='a-1'
                          type='checkbox'
                        />
                        <label htmlFor='a-1' className='form-check-label'>
                          Remember Me
                        </label>
                      </div>
                      <div className='exclop-last'>
                        <Link to='/' className='fw-medium text-primary'>
                          Forget Password?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /row */}
          {/* row Start */}
          <div className='row form-submit'>
            <div className='col-lg-8 col-md-8 col-sm-12'>
              {/* row */}
              <div className='row m-0'>
                <div className='submit-page'>
                  <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <h3>Billing Detail</h3>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label>
                          Name<i className='req'>*</i>
                        </label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label>
                          Email<i className='req'>*</i>
                        </label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <div className='form-group'>
                        <label>Company Name</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <div className='form-group'>
                        <label>
                          Country<i className='req'>*</i>
                        </label>
                        <select id='country' className='form-control'>
                          <option value>&nbsp;</option>
                          <option value={1}>United State</option>
                          <option value={2}>United kingdom</option>
                          <option value={3}>India</option>
                          <option value={4}>Canada</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <div className='form-group'>
                        <label>
                          Street<i className='req'>*</i>
                        </label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label>Apartment</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label>
                          Town/City<i className='req'>*</i>
                        </label>
                        <select id='town' className='form-control'>
                          <option value>&nbsp;</option>
                          <option value={1}>Punjab</option>
                          <option value={2}>Chandigarh</option>
                          <option value={3}>Allahabad</option>
                          <option value={4}>Lucknow</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label>
                          State<i className='req'>*</i>
                        </label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label>
                          Postcode/Zip<i className='req'>*</i>
                        </label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label>
                          Phone<i className='req'>*</i>
                        </label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label>Landline</label>
                        <input type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                      <div className='form-group'>
                        <label>Additional Information</label>
                        <textarea
                          className='form-control ht-50'
                          defaultValue=''
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <input
                          id='a-2'
                          className='form-check-input'
                          name='a-2'
                          type='checkbox'
                        />
                        <label htmlFor='a-2' className='form-check-label'>
                          Create An Account
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /row */}
            </div>
            {/* Col-lg 4 */}
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <h3>Your Order</h3>
              </div>
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div className='product-wrap'>
                  <h5>Platinum</h5>
                  <ul>
                    <li>
                      <strong>Total</strong>$319
                    </li>
                    <li>
                      <strong>Subtotal</strong>$319
                    </li>
                    <li>
                      <strong>Tax</strong>$10
                    </li>
                    <li>
                      <strong>Total</strong>$329
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div
                  className='alert bg-danger text-light text-center'
                  role='alert'
                >
                  Have You Coupon?
                  <Link
                    to='/'
                    className='text-warning'
                    data-bs-toggle='collapse'
                    data-bs-target='#coupon-frm'
                  >
                    Click Here
                  </Link>
                </div>
              </div>
              <div className='col-lg-12 col-md-12 col-sm-12 mb-2'>
                <div id='coupon-frm' className='collapse'>
                  <input
                    type='text'
                    className='form-control mb-2'
                    placeholder='Coupon Code'
                  />
                  <button
                    type='submit'
                    className='btn btn-primary full-width mb-2'
                  >
                    Apply Coupon
                  </button>
                </div>
              </div>
              <div className='col-lg-12 col-md-12 col-sm-12'>
                <div className='pay-wrap'>
                  <div className='pay-wrap-header'>
                    <h4>Platinum</h4>
                    <div className='pw-right'>
                      <h3 className='text-primary'>
                        $12<sub>\Month</sub>
                      </h3>
                    </div>
                  </div>
                  <div className='pay-wrap-content'>
                    <div className='pw-first-content'>
                      <h4>Your Features</h4>
                      <button
                        type='button'
                        data-toggle='collapse'
                        data-target='#change-plan'
                      >
                        Change Plan
                      </button>
                    </div>
                    <div id='change-plan' className='collapse'>
                      <ul className='no-ul-list'>
                        <li>
                          <input
                            id='basic'
                            className='form-check-input'
                            name='plan'
                            type='radio'
                          />
                          <label htmlFor='basic' className='form-check-label'>
                            Basic Plan
                          </label>
                        </li>
                        <li>
                          <input
                            id='platinum'
                            className='form-check-input'
                            name='plan'
                            type='radio'
                            defaultChecked
                          />
                          <label
                            htmlFor='platinum'
                            className='form-check-label'
                          >
                            Platinum
                          </label>
                        </li>
                        <li>
                          <input
                            id='standard'
                            className='form-check-input'
                            name='plan'
                            type='radio'
                          />
                          <label
                            htmlFor='standard'
                            className='form-check-label'
                          >
                            Standard
                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className='pw-content-detail'>
                      <ul className='pw-features'>
                        <li>First Features</li>
                        <li>Second Features</li>
                        <li>Third Features</li>
                        <li>Fourth Features</li>
                      </ul>
                    </div>
                    <div className='pw-btn-wrap'>
                      <Link
                        to='/payment'
                        className='btn btn-primary rounded full-width'
                      >
                        Proceed Payment
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /col-lg-4 */}
          </div>
          {/* /row */}
        </div>
      </section>
      {/* ============================ Our Story End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
