import React from 'react';
import { Link } from 'react-router-dom';

export default function Contact() {
  return (
    <div>
      {/* ============================ Page Title Start================================== */}
      <div className='page-title'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <h2 className='ipt-title'>Contact Us</h2>
              <span className='ipn-subtitle'>
                Lists of our all contact details
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Page Title End ================================== */}
      {/* ============================ Agency List Start ================================== */}
      <section>
        <div className='container'>
          {/* row Start */}
          <div className='row'>
            <div className='col-lg-7 col-md-7'>
              <div className='row'>
                <div className='col-lg-6 col-md-6'>
                  <div className='form-group'>
                    <label>Name</label>
                    <input type='text' className='form-control simple' />
                  </div>
                </div>
                <div className='col-lg-6 col-md-6'>
                  <div className='form-group'>
                    <label>Email</label>
                    <input type='email' className='form-control simple' />
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label>Subject</label>
                <input type='text' className='form-control simple' />
              </div>
              <div className='form-group'>
                <label>Message</label>
                <textarea className='form-control simple' defaultValue='' />
              </div>
              <div className='form-group'>
                <button className='btn btn-primary px-5 rounded' type='submit'>
                  Submit Request
                </button>
              </div>
            </div>
            <div className='col-lg-5 col-md-5'>
              <div className='contact-info'>
                <h2>Get In Touch</h2>
                <p>Connect with us for any inquiries or assistance.</p>
                <div className='cn-info-detail'>
                  <div className='cn-info-icon'>
                    <i className='fa-solid fa-house' />
                  </div>
                  <div className='cn-info-content'>
                    <h4 className='cn-info-title'>Reach Us</h4>
                    Shop No. F-13, Plot No. 1, Myfaire Complex,
                    <br />
                    Khivansara Park, Garkheda Parisar, Chatrapati
                    <br />
                    Sambhajinagar (Aurangabad), Maharashtra
                    <br /> 431001, India
                  </div>
                </div>
                <div className='cn-info-detail'>
                  <div className='cn-info-icon'>
                    <i className='fa-solid fa-envelope-circle-check' />
                  </div>
                  <div className='cn-info-content'>
                    <h4 className='cn-info-title'>Drop A Mail</h4>
                    hello@avrealties.com
                    <br />
                  </div>
                </div>
                <div className='cn-info-detail'>
                  <div className='cn-info-icon'>
                    <i className='fa-solid fa-phone-volume' />
                  </div>
                  <div className='cn-info-content'>
                    <h4 className='cn-info-title'>Call Us</h4>
                    +91 9421683738
                    <br />
                    +91 8830851524
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /row */}
        </div>
      </section>
      {/* ============================ Agency List End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
