import React from 'react';
import { Link } from 'react-router-dom';

export default function BlogDetail() {
  return (
    <div>
      {/* ============================ Page Title Start================================== */}
      <div className='page-title'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <h2 className='ipt-title'>Blog Detail</h2>
              <span className='ipn-subtitle'>
                See Our Latest Articles &amp; News
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Page Title End ================================== */}
      {/* ============================ Agency List Start ================================== */}
      <section className='gray-simple'>
        <div className='container'>
          {/* row Start */}
          <div className='row'>
            {/* Blog Detail */}
            <div className='col-lg-8 col-md-12 col-sm-12 col-12'>
              <div className='blog-details single-post-item format-standard'>
                <div className='post-details'>
                  <div className='post-featured-img'>
                    <img
                      className='img-fluid'
                      src='https://via.placeholder.com/1200x850'
                      alt=''
                    />
                  </div>
                  <div className='post-top-meta'>
                    <ul className='meta-comment-tag'>
                      <li>
                        <Link to='/'>
                          <span className='icons'>
                            <i className='ti-user' />
                          </span>
                          by Rosalina Doe
                        </Link>
                      </li>
                      <li>
                        <Link to='/'>
                          <span className='icons'>
                            <i className='ti-comment-alt' />
                          </span>
                          45 Comments
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h2 className='post-title'>
                    Lorem ipsum dolor sit amet, cons pisicing elit, sed do.
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum. Sed ut perspiciatis
                    unde omnis iste natus error sit voluptatem accusantium
                    doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
                    illo inventore veritatis et quasi architecto beatae vitae
                    dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                    voluptas sit aspernatur aut odit aut fugit, sed quia
                    consequuntur magni dolores eos qui ratione voluptatem sequi
                    nesciunt. Neque porro quisquam est, qui dolorem.
                    <br />
                    <br />
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum. Sed ut perspiciatis
                    unde omnis iste natus error sit voluptatem accusantium
                    doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
                    illo inventore veritatis et quasi architecto beatae vitae
                    dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                    voluptas sit aspernatur aut odit aut fugit, sed quia
                    consequuntur magni dolores eos qui ratione voluptatem.
                  </p>
                  <blockquote>
                    <span className='icon'>
                      <i className='fas fa-quote-left' />
                    </span>
                    <p className='text'>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tem ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud ullamco laboris nisi ut
                      aliquip ex ea commodo onsequat.
                    </p>
                    <h5 className='name'>- Rosalina Pong</h5>
                  </blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum. Sed ut perspiciatis
                    unde omnis iste natus error sit voluptatem accusantium
                    doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
                    illo inventore veritatis et quasi architecto beatae vitae
                    dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                    voluptas sit aspernatur aut odit aut fugit, sed quia
                    consequuntur magni dolores eos qui ratione voluptatem sequi
                    nesciunt. Neque porro quisquam est, qui dolorem.
                    <br />
                    <br />
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum. Sed ut perspiciatis
                    unde omnis iste natus error sit voluptatem accusantium
                    doloremque laudantium, totam rem aperiam, eaque ipsa quae ab
                    illo inventore veritatis et quasi architecto beatae vitae
                    dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                    voluptas sit aspernatur aut odit aut fugit, sed quia
                    consequuntur magni dolores eos qui ratione voluptatem.
                  </p>
                  <div className='post-bottom-meta'>
                    <div className='post-tags'>
                      <h4 className='pbm-title'>Related Tags</h4>
                      <ul className='list'>
                        <li>
                          <Link to='/'>organic</Link>
                        </li>
                        <li>
                          <Link to='/'>Foods</Link>
                        </li>
                        <li>
                          <Link to='/'>tasty</Link>
                        </li>
                      </ul>
                    </div>
                    <div className='post-share'>
                      <h4 className='pbm-title'>Social Share</h4>
                      <ul className='list'>
                        <li>
                          <Link to='/'>
                            <i className='fab fa-facebook-f' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/'>
                            <i className='fab fa-twitter' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/'>
                            <i className='fab fa-linkedin-in' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/'>
                            <i className='fab fa-vk' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/'>
                            <i className='fab fa-tumblr' />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='single-post-pagination'>
                    <div className='prev-post'>
                      <Link to='/'>
                        <div className='title-with-link'>
                          <span className='intro'>Prev Post</span>
                          <h3 className='title'>Tips on Minimalist</h3>
                        </div>
                      </Link>
                    </div>
                    <div className='post-pagination-center-grid'>
                      <Link to='/'>
                        <i className='ti-layout-grid3' />
                      </Link>
                    </div>
                    <div className='next-post'>
                      <Link to='/'>
                        <div className='title-with-link'>
                          <span className='intro'>Next Post</span>
                          <h3 className='title'>Less Is More</h3>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* Author Detail */}
              <div className='blog-details single-post-item format-standard'>
                <div className='posts-author'>
                  <span className='img'>
                    <img
                      className='img-fluid'
                      src='https://via.placeholder.com/800x800'
                      alt=''
                    />
                  </span>
                  <h3 className='pa-name'>Rosalina William</h3>
                  <ul className='social-links'>
                    <li>
                      <Link to='/'>
                        <i className='fab fa-facebook-f' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/'>
                        <i className='fab fa-twitter' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/'>
                        <i className='fab fa-behance' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/'>
                        <i className='fab fa-youtube' />
                      </Link>
                    </li>
                    <li>
                      <Link to='/'>
                        <i className='fab fa-linkedin-in' />
                      </Link>
                    </li>
                  </ul>
                  <p className='pa-text'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo.
                  </p>
                </div>
              </div>
              {/* Blog Comment */}
              <div className='blog-details single-post-item format-standard'>
                <div className='comment-area'>
                  <div className='all-comments'>
                    <h3 className='comments-title'>05 Comments</h3>
                    <div className='comment-list'>
                      <ul>
                        <li className='single-comment'>
                          <article>
                            <div className='comment-author'>
                              <img
                                src='https://via.placeholder.com/800x800'
                                alt=''
                              />
                            </div>
                            <div className='comment-details'>
                              <div className='comment-meta'>
                                <div className='comment-left-meta'>
                                  <h4 className='author-name'>
                                    Rosalina Kelian
                                    <span className='selected'>
                                      <i className='fas fa-bookmark' />
                                    </span>
                                  </h4>
                                  <div className='comment-date'>
                                    19th May 2018
                                  </div>
                                </div>
                                <div className='comment-reply'>
                                  <Link to='/' className='reply'>
                                    <span className='icona'>
                                      <i className='ti-back-left' />
                                    </span>
                                    Reply
                                  </Link>
                                </div>
                              </div>
                              <div className='comment-text'>
                                <p>
                                  Excepteur sint occaecat cupidatat non
                                  proident, sunt in culpa qui officia deserunt
                                  mollit anim laborumab. perspiciatis unde omnis
                                  iste natus error.
                                </p>
                              </div>
                            </div>
                          </article>
                          <ul className='children'>
                            <li className='single-comment'>
                              <article>
                                <div className='comment-author'>
                                  <img
                                    src='https://via.placeholder.com/800x800'
                                    alt=''
                                  />
                                </div>
                                <div className='comment-details'>
                                  <div className='comment-meta'>
                                    <div className='comment-left-meta'>
                                      <h4 className='author-name'>
                                        Rosalina Kelian
                                      </h4>
                                      <div className='comment-date'>
                                        19th May 2018
                                      </div>
                                    </div>
                                    <div className='comment-reply'>
                                      <Link to='/' className='reply'>
                                        <span className='icons'>
                                          <i className='ti-back-left' />
                                        </span>
                                        Reply
                                      </Link>
                                    </div>
                                  </div>
                                  <div className='comment-text'>
                                    <p>
                                      Excepteur sint occaecat cupidatat non
                                      proident, sunt in culpa qui officia
                                      deserunt mollit anim laborumab.
                                      perspiciatis unde omnis iste natus error.
                                    </p>
                                  </div>
                                </div>
                              </article>
                              <ul className='children'>
                                <li className='single-comment'>
                                  <article>
                                    <div className='comment-author'>
                                      <img
                                        src='https://via.placeholder.com/800x800'
                                        alt=''
                                      />
                                    </div>
                                    <div className='comment-details'>
                                      <div className='comment-meta'>
                                        <div className='comment-left-meta'>
                                          <h4 className='author-name'>
                                            Rosalina Kelian
                                          </h4>
                                          <div className='comment-date'>
                                            19th May 2018
                                          </div>
                                        </div>
                                        <div className='comment-reply'>
                                          <Link to='/' className='reply'>
                                            <span className='icons'>
                                              <i className='ti-back-left' />
                                            </span>
                                            Reply
                                          </Link>
                                        </div>
                                      </div>
                                      <div className='comment-text'>
                                        <p>
                                          Excepteur sint occaecat cupidatat non
                                          proident, sunt in culpa qui officia
                                          deserunt mollit anim laborumab.
                                          perspiciatis unde omnis iste natus
                                          error.
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className='single-comment'>
                          <article>
                            <div className='comment-author'>
                              <img
                                src='https://via.placeholder.com/800x800'
                                alt=''
                              />
                            </div>
                            <div className='comment-details'>
                              <div className='comment-meta'>
                                <div className='comment-left-meta'>
                                  <h4 className='author-name'>
                                    Rosalina Kelian
                                  </h4>
                                  <div className='comment-date'>
                                    19th May 2018
                                  </div>
                                </div>
                                <div className='comment-reply'>
                                  <Link to='/' className='reply'>
                                    <span className='icons'>
                                      <i className='ti-back-left' />
                                    </span>
                                    Reply
                                  </Link>
                                </div>
                              </div>
                              <div className='comment-text'>
                                <p>
                                  Excepteur sint occaecat cupidatat non
                                  proident, sunt in culpa qui officia deserunt
                                  mollit anim laborumab. perspiciatis unde omnis
                                  iste natus error.
                                </p>
                              </div>
                            </div>
                          </article>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='comment-box submit-form'>
                    <h3 className='reply-title'>Post Comment</h3>
                    <div className='comment-form'>
                      <form action='#'>
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='form-group'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Your Name'
                              />
                            </div>
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='form-group'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Your Email'
                              />
                            </div>
                          </div>
                          <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='form-group'>
                              <textarea
                                name='comment'
                                className='form-control'
                                cols={30}
                                rows={6}
                                placeholder='Type your comments....'
                                defaultValue=''
                              />
                            </div>
                          </div>
                          <div className='col-lg-12 col-md-12 col-sm-12'>
                            <div className='form-group'>
                              <Link
                                to='/'
                                className='btn btn-primary rounded full-width'
                              >
                                Submit Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Single blog Grid */}
            <div className='col-lg-4 col-md-12 col-sm-12 col-12'>
              {/* Searchbard */}
              <div className='single-widgets widget_search'>
                <h4 className='title'>Search</h4>
                <form className='sidebar-search-form'>
                  <input type='search' name='search' placeholder='Search..' />
                  <button type='submit'>
                    <i className='fa-solid fa-magnifying-glass' />
                  </button>
                </form>
              </div>
              {/* Categories */}
              <div className='single-widgets widget_category'>
                <h4 className='title'>Categories</h4>
                <ul>
                  <li>
                    <Link to='/'>
                      Lifestyle <span>09</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/'>
                      Travel <span>12</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/'>
                      Fashion <span>19</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/'>
                      Branding <span>17</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/'>
                      Music <span>10</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* Trending Posts */}
              <div className='single-widgets widget_thumb_post'>
                <h4 className='title'>Trending Posts</h4>
                <ul>
                  <li>
                    <span className='left'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className
                      />
                    </span>
                    <span className='right'>
                      <Link className='feed-title' to='/'>
                        Alonso Kelina Falao Asiano Pero
                      </Link>
                      <span className='post-date'>
                        <i className='ti-calendar' />
                        10 Min ago
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className='left'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className
                      />
                    </span>
                    <span className='right'>
                      <Link className='feed-title' to='/'>
                        It is a long established fact that a reader
                      </Link>
                      <span className='post-date'>
                        <i className='ti-calendar' />2 Hours ago
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className='left'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className
                      />
                    </span>
                    <span className='right'>
                      <Link className='feed-title' to='/'>
                        Many desktop publish packages and web
                      </Link>
                      <span className='post-date'>
                        <i className='ti-calendar' />4 Hours ago
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className='left'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className
                      />
                    </span>
                    <span className='right'>
                      <Link className='feed-title' to='/'>
                        Various versions have evolved over the years
                      </Link>
                      <span className='post-date'>
                        <i className='ti-calendar' />7 Hours ago
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className='left'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className
                      />
                    </span>
                    <span className='right'>
                      <Link className='feed-title' to='/'>
                        Photo booth anim 8-bit PBR 3 wolf moon.
                      </Link>
                      <span className='post-date'>
                        <i className='ti-calendar' />3 Days ago
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
              {/* Tags Cloud */}
              <div className='single-widgets widget_tags'>
                <h4 className='title'>Tags Cloud</h4>
                <ul>
                  <li>
                    <Link to='/'>Lifestyle</Link>
                  </li>
                  <li>
                    <Link to='/'>Travel</Link>
                  </li>
                  <li>
                    <Link to='/'>Fashion</Link>
                  </li>
                  <li>
                    <Link to='/'>Branding</Link>
                  </li>
                  <li>
                    <Link to='/'>Music</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /row */}
        </div>
      </section>
      {/* ============================ Agency List End ================================== */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/create-account' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
