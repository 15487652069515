import React from 'react';
import { Link } from 'react-router-dom';

export default function HeaderOffCanvas() {
  return (
    <div
      className='offcanvas offcanvas-end'
      data-bs-scroll='true'
      data-bs-backdrop='false'
      tabIndex={-1}
      id='offcanvasScrolling'
      aria-labelledby='offcanvasScrollingLabel'
    >
      <div className='offcanvas-header'>
        <h5 className='offcanvas-title' id='offcanvasScrollingLabel'>
          Compare &amp; Selected Property
        </h5>
        <Link to='/' data-bs-dismiss='offcanvas' aria-label='Close'>
          <span className='svg-icon text-primary svg-icon-2hx'>
            <svg
              width={32}
              height={32}
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                opacity='0.3'
                x={2}
                y={2}
                width={20}
                height={20}
                rx={10}
                fill='currentColor'
              />
              <rect
                x={7}
                y='15.3137'
                width={12}
                height={2}
                rx={1}
                transform='rotate(-45 7 15.3137)'
                fill='currentColor'
              />
              <rect
                x='8.41422'
                y={7}
                width={12}
                height={2}
                rx={1}
                transform='rotate(45 8.41422 7)'
                fill='currentColor'
              />
            </svg>
          </span>
        </Link>
      </div>
      <div className='offcanvas-body'>
        <ul
          className='nav nav-pills sider_tab mb-3'
          id='pills-tab'
          role='tablist'
        >
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link active'
              id='pills-compare-tab'
              data-bs-toggle='pill'
              data-bs-target='#pills-compare'
              type='button'
              role='tab'
              aria-controls='pills-compare'
              aria-selected='true'
            >
              Compare Property
            </button>
          </li>
          <li className='nav-item' role='presentation'>
            <button
              className='nav-link'
              id='pills-saved-tab'
              data-bs-toggle='pill'
              data-bs-target='#pills-saved'
              type='button'
              role='tab'
              aria-controls='pills-saved'
              aria-selected='false'
            >
              Saved Property
            </button>
          </li>
        </ul>
        <div className='tab-content' id='pills-tabContent'>
          <div
            className='tab-pane fade show active'
            id='pills-compare'
            role='tabpanel'
            aria-labelledby='pills-compare-tab'
            tabIndex={0}
          >
            <div className='sidebar_featured_property'>
              {/* List Sibar Property */}
              <div className='sides_list_property p-2'>
                <div className='sides_list_property_thumb'>
                  <img
                    src='https://via.placeholder.com/1200x850'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div className='sides_list_property_detail'>
                  <h4>
                    <Link to='/single-property-1'>
                      Loss vengel New Apartment
                    </Link>
                  </h4>
                  <span className='text-muted-2'>
                    <i className='fa-solid fa-location-dot' />
                    Sans Fransico
                  </span>
                  <div className='lists_property_price'>
                    <div className='lists_property_types'>
                      <div className='property_types_vlix sale'>For Sale</div>
                    </div>
                    <div className='lists_property_price_value'>
                      <h4 className='text-primary'>₹4,240</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* List Sibar Property */}
              <div className='sides_list_property p-2'>
                <div className='sides_list_property_thumb'>
                  <img
                    src='https://via.placeholder.com/1200x850'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div className='sides_list_property_detail'>
                  <h4>
                    <Link to='/single-property-1'>
                      Montreal Quriqe Apartment
                    </Link>
                  </h4>
                  <span className='text-muted-2'>
                    <i className='fa-solid fa-location-dot' />
                    Liverpool, London
                  </span>
                  <div className='lists_property_price'>
                    <div className='lists_property_types'>
                      <div className='property_types_vlix'>For Rent</div>
                    </div>
                    <div className='lists_property_price_value'>
                      <h4 className='text-primary'>₹7,380</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* List Sibar Property */}
              <div className='sides_list_property p-2'>
                <div className='sides_list_property_thumb'>
                  <img
                    src='https://via.placeholder.com/1200x850'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div className='sides_list_property_detail'>
                  <h4>
                    <Link to='/single-property-1'>
                      Curmic Studio For Office
                    </Link>
                  </h4>
                  <span className='text-muted-2'>
                    <i className='fa-solid fa-location-dot' />
                    Montreal, Canada
                  </span>
                  <div className='lists_property_price'>
                    <div className='lists_property_types'>
                      <div className='property_types_vlix buy'>For Buy</div>
                    </div>
                    <div className='lists_property_price_value'>
                      <h4 className='text-primary'>₹8,730</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* List Sibar Property */}
              <div className='sides_list_property p-2'>
                <div className='sides_list_property_thumb'>
                  <img
                    src='https://via.placeholder.com/1200x850'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div className='sides_list_property_detail'>
                  <h4>
                    <Link to='/single-property-1'>Montreal Quebec City</Link>
                  </h4>
                  <span className='text-muted-2'>
                    <i className='fa-solid fa-location-dot' />
                    Sreek View, New York
                  </span>
                  <div className='lists_property_price'>
                    <div className='lists_property_types'>
                      <div className='property_types_vlix'>For Rent</div>
                    </div>
                    <div className='lists_property_price_value'>
                      <h4 className='text-primary'>₹6,240</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className='input-group'>
                <Link
                  to='/compare-property'
                  className='btn btn-light-primary fw-medium full-width'
                >
                  View &amp; Compare
                </Link>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='pills-saved'
            role='tabpanel'
            aria-labelledby='pills-saved-tab'
            tabIndex={0}
          >
            <div className='sidebar_featured_property'>
              {/* List Sibar Property */}
              <div className='sides_list_property p-2'>
                <div className='sides_list_property_thumb'>
                  <img
                    src='https://via.placeholder.com/1200x850'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div className='sides_list_property_detail'>
                  <h4>
                    <Link to='/single-property-1'>
                      Loss vengel New Apartment
                    </Link>
                  </h4>
                  <span className='text-muted-2'>
                    <i className='fa-solid fa-location-dot' />
                    Sans Fransico
                  </span>
                  <div className='lists_property_price'>
                    <div className='lists_property_types'>
                      <div className='property_types_vlix sale'>For Sale</div>
                    </div>
                    <div className='lists_property_price_value'>
                      <h4 className='text-primary'>₹4,240</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* List Sibar Property */}
              <div className='sides_list_property p-2'>
                <div className='sides_list_property_thumb'>
                  <img
                    src='https://via.placeholder.com/1200x850'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div className='sides_list_property_detail'>
                  <h4>
                    <Link to='/single-property-1'>
                      Montreal Quriqe Apartment
                    </Link>
                  </h4>
                  <span className='text-muted-2'>
                    <i className='fa-solid fa-location-dot' />
                    Liverpool, London
                  </span>
                  <div className='lists_property_price'>
                    <div className='lists_property_types'>
                      <div className='property_types_vlix'>For Rent</div>
                    </div>
                    <div className='lists_property_price_value'>
                      <h4 className='text-primary'>₹7,380</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* List Sibar Property */}
              <div className='sides_list_property p-2'>
                <div className='sides_list_property_thumb'>
                  <img
                    src='https://via.placeholder.com/1200x850'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div className='sides_list_property_detail'>
                  <h4>
                    <Link to='/single-property-1'>
                      Curmic Studio For Office
                    </Link>
                  </h4>
                  <span className='text-muted-2'>
                    <i className='fa-solid fa-location-dot' />
                    Montreal, Canada
                  </span>
                  <div className='lists_property_price'>
                    <div className='lists_property_types'>
                      <div className='property_types_vlix buy'>For Buy</div>
                    </div>
                    <div className='lists_property_price_value'>
                      <h4 className='text-primary'>₹8,730</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* List Sibar Property */}
              <div className='sides_list_property p-2'>
                <div className='sides_list_property_thumb'>
                  <img
                    src='https://via.placeholder.com/1200x850'
                    className='img-fluid'
                    alt=''
                  />
                </div>
                <div className='sides_list_property_detail'>
                  <h4>
                    <Link to='/single-property-1'>Montreal Quebec City</Link>
                  </h4>
                  <span className='text-muted-2'>
                    <i className='fa-solid fa-location-dot' />
                    Sreek View, New York
                  </span>
                  <div className='lists_property_price'>
                    <div className='lists_property_types'>
                      <div className='property_types_vlix'>For Rent</div>
                    </div>
                    <div className='lists_property_price_value'>
                      <h4 className='text-primary'>₹6,240</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className='input-group'>
                <Link
                  to='/'
                  className='btn btn-light-primary fw-medium full-width'
                >
                  View &amp; Compare
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
