import React from 'react';
import { Link } from 'react-router-dom';
// import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  // const error = useRouteError();
  // console.error(error);

  return (
    <>
      <section className='error-wrap'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <div className='text-center'>
                <img src='assets/img/404.png' className='img-fluid' alt='' />
                <p>
                  Maecenas quis consequat libero, a feugiat eros. Nunc ut
                  lacinia tortor morbi ultricies laoreet ullamcorper phasellus
                  semper
                </p>
                <Link className='btn btn-primary px-5' to='/'>
                  Back To Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>Well help you to grow your career and growth.</span>
                </div>
                <Link to='/' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
