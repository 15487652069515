import React from 'react';
import { Link } from 'react-router-dom';

export default function Login() {
  return (
    <div
      className='modal fade'
      id='login'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='registermodal'
      aria-hidden='true'
    >
      <div
        className='modal-dialog modal-dialog-centered login-pop-form'
        role='document'
      >
        <div className='modal-content' id='registermodal'>
          <span
            className='mod-close'
            data-bs-dismiss='modal'
            aria-hidden='true'
          >
            <span className='svg-icon text-primary svg-icon-2hx'>
              <svg
                width={32}
                height={32}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  opacity='0.3'
                  x={2}
                  y={2}
                  width={20}
                  height={20}
                  rx={10}
                  fill='currentColor'
                />
                <rect
                  x={7}
                  y='15.3137'
                  width={12}
                  height={2}
                  rx={1}
                  transform='rotate(-45 7 15.3137)'
                  fill='currentColor'
                />
                <rect
                  x='8.41422'
                  y={7}
                  width={12}
                  height={2}
                  rx={1}
                  transform='rotate(45 8.41422 7)'
                  fill='currentColor'
                />
              </svg>
            </span>
          </span>
          <div className='modal-body'>
            <h4 className='modal-header-title'>Log In</h4>
            <div className='d-flex align-items-center justify-content-center mb-3'>
              <span className='svg-icon text-primary svg-icon-2hx'>
                <svg
                  width={80}
                  height={80}
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M15.8797 15.375C15.9797 15.075 15.9797 14.775 15.9797 14.475C15.9797 13.775 15.7797 13.075 15.4797 12.475C14.7797 11.275 13.4797 10.475 11.9797 10.475C11.7797 10.475 11.5797 10.475 11.3797 10.575C7.37971 11.075 4.67971 14.575 2.57971 18.075L10.8797 3.675C11.3797 2.775 12.5797 2.775 13.0797 3.675C13.1797 3.875 13.2797 3.975 13.3797 4.175C15.2797 7.575 16.9797 11.675 15.8797 15.375Z'
                    fill='currentColor'
                  />
                  <path
                    opacity='0.3'
                    d='M20.6797 20.6749C16.7797 20.6749 12.3797 20.275 9.57972 17.575C10.2797 18.075 11.0797 18.375 11.9797 18.375C13.4797 18.375 14.7797 17.5749 15.4797 16.2749C15.6797 15.9749 15.7797 15.675 15.7797 15.375V15.2749C16.8797 11.5749 15.2797 7.47495 13.2797 4.07495L21.6797 18.6749C22.2797 19.5749 21.6797 20.6749 20.6797 20.6749ZM8.67972 18.6749C8.17972 17.8749 7.97972 16.975 7.77972 15.975C7.37972 13.575 8.67972 10.775 11.3797 10.375C7.37972 10.875 4.67972 14.375 2.57972 17.875C2.47972 18.075 2.27972 18.375 2.17972 18.575C1.67972 19.475 2.27972 20.475 3.27972 20.475H10.3797C9.67972 20.175 9.07972 19.3749 8.67972 18.6749Z'
                    fill='currentColor'
                  />
                </svg>
              </span>
            </div>
            <div className='login-form'>
              <form>
                <div className='form-floating mb-3'>
                  <input
                    type='email'
                    className='form-control'
                    placeholder='name@example.com'
                  />
                  <label>Email address</label>
                </div>
                <div className='form-floating mb-3'>
                  <input
                    type='password'
                    className='form-control'
                    placeholder='Password'
                  />
                  <label>Password</label>
                </div>
                <div className='form-group mb-3'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='flex-shrink-0 flex-first'>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='save-pass'
                          defaultValue='option1'
                        />
                        <label className='form-check-label' htmlFor='save-pass'>
                          Save Password
                        </label>
                      </div>
                    </div>
                    <div className='flex-shrink-0 flex-first'>
                      <Link to='/' className='link fw-medium'>
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary fw-medium full-width rounded-2'
                  >
                    LogIn
                  </button>
                </div>
              </form>
            </div>
            <div className='modal-divider'>
              <span>Or login via</span>
            </div>
            <div className='social-login mb-3'>
              <ul>
                <li>
                  <Link to='/' className='btn connect-fb'>
                    <i className='ti-facebook' />
                    Facebook
                  </Link>
                </li>
                <li>
                  <Link to='/' className='btn connect-google'>
                    <i className='ti-google' />
                    Google+
                  </Link>
                </li>
              </ul>
            </div>
            <div className='text-center'>
              <p className='mt-4'>
                Have Any Account?
                <Link to='/create-account' className='link fw-medium'>
                  Acreate An Account
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
