import React, { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Plausible from 'plausible-tracker';

const plausible = Plausible({
  domain: process.env.REACT_APP_UI_DOMAIN_HOST,
  apiHost: process.env.REACT_APP_API_DOMAIN_HOST,
});

function PlausibleProvider() {
  const location = useLocation();

  useEffect(() => {
    // Enable automatic outbound link tracking
    const cleanup = plausible.enableAutoOutboundTracking();
    return () => {
      cleanup();
    };
  }, []);

  useEffect(() => {
    // Track pageviews on route changes
    plausible.trackPageview({
      url: location.pathname + location.search, // Include search parameters if needed
    });
  }, [location]);

  return <Outlet />;
}

export default PlausibleProvider;
