import React from 'react';
import { Link } from 'react-router-dom';

export default function About() {
  return (
    <div>
      {/* ============================ Page Title Start================================== */}
      <div className='page-title'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <h2 className='ipt-title'>About Us</h2>
              <span className='ipn-subtitle'>Who we are &amp; our mission</span>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Page Title End ================================== */}
      {/* ============================ Our Story Start ================================== */}
      <section>
        <div className='container'>
          {/* row Start */}
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-6'>
              <img
                src='https://via.placeholder.com/800x900'
                className='img-fluid'
                alt=''
              />
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='story-wrap explore-content'>
                <h2>Our Story</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip.
                </p>
              </div>
            </div>
          </div>
          {/* /row */}
        </div>
      </section>
      {/* ============================ Our Story End ================================== */}
      {/* ================= Our Team================= */}
      <section className='gray-bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='sec-heading center'>
                <h2>Meet Our Team</h2>
                <p>Professional &amp; Dedicated Team</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='team-slide item-slide'>
                {/* Single Teamm */}
                <div className='single-team'>
                  <div className='team-grid'>
                    <div className='teamgrid-user'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <div className='teamgrid-content'>
                      <h4>Shaurya Preet</h4>
                      <span>Co-Founder</span>
                    </div>
                    <div className='teamgrid-social'>
                      <ul>
                        <li>
                          <Link to='/' className='f-cl'>
                            <i className='fa-brands fa-facebook' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='t-cl'>
                            <i className='fa-brands fa-twitter' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='i-cl'>
                            <i className='fa-brands fa-instagram' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='l-cl'>
                            <i className='fa-brands fa-linkedin' />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Single Teamm */}
                <div className='single-team'>
                  <div className='team-grid'>
                    <div className='teamgrid-user'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <div className='teamgrid-content'>
                      <h4>Shivangi Preet</h4>
                      <span>Content Writer</span>
                    </div>
                    <div className='teamgrid-social'>
                      <ul>
                        <li>
                          <Link to='/' className='f-cl'>
                            <i className='fa-brands fa-facebook' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='t-cl'>
                            <i className='fa-brands fa-twitter' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='i-cl'>
                            <i className='fa-brands fa-instagram' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='l-cl'>
                            <i className='fa-brands fa-linkedin' />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Single Teamm */}
                <div className='single-team'>
                  <div className='team-grid'>
                    <div className='teamgrid-user'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <div className='teamgrid-content'>
                      <h4>Yash Preet</h4>
                      <span>Content Writer</span>
                    </div>
                    <div className='teamgrid-social'>
                      <ul>
                        <li>
                          <Link to='/' className='f-cl'>
                            <i className='fa-brands fa-facebook' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='t-cl'>
                            <i className='fa-brands fa-twitter' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='i-cl'>
                            <i className='fa-brands fa-instagram' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='l-cl'>
                            <i className='fa-brands fa-linkedin' />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Single Teamm */}
                <div className='single-team'>
                  <div className='team-grid'>
                    <div className='teamgrid-user'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <div className='teamgrid-content'>
                      <h4>Dhananjay Preet</h4>
                      <span>CEO &amp; Manager</span>
                    </div>
                    <div className='teamgrid-social'>
                      <ul>
                        <li>
                          <Link to='/' className='f-cl'>
                            <i className='fa-brands fa-facebook' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='t-cl'>
                            <i className='fa-brands fa-twitter' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='i-cl'>
                            <i className='fa-brands fa-instagram' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='l-cl'>
                            <i className='fa-brands fa-linkedin' />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Single Teamm */}
                <div className='single-team'>
                  <div className='team-grid'>
                    <div className='teamgrid-user'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <div className='teamgrid-content'>
                      <h4>Rahul Gilkrist</h4>
                      <span>App Designer</span>
                    </div>
                    <div className='teamgrid-social'>
                      <ul>
                        <li>
                          <Link to='/' className='f-cl'>
                            <i className='fa-brands fa-facebook' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='t-cl'>
                            <i className='fa-brands fa-twitter' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='i-cl'>
                            <i className='fa-brands fa-instagram' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='l-cl'>
                            <i className='fa-brands fa-linkedin' />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Single Teamm */}
                <div className='single-team'>
                  <div className='team-grid'>
                    <div className='teamgrid-user'>
                      <img
                        src='https://via.placeholder.com/800x800'
                        alt=''
                        className='img-fluid'
                      />
                    </div>
                    <div className='teamgrid-content'>
                      <h4>Adam Wilcard</h4>
                      <span>Web Developer</span>
                    </div>
                    <div className='teamgrid-social'>
                      <ul>
                        <li>
                          <Link to='/' className='f-cl'>
                            <i className='fa-brands fa-facebook' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='t-cl'>
                            <i className='fa-brands fa-twitter' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='i-cl'>
                            <i className='fa-brands fa-instagram' />
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className='l-cl'>
                            <i className='fa-brands fa-linkedin' />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =============================== Our Team ================================== */}
      {/* ================= Our Mission ================= */}
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='sec-heading center'>
                <h2>Our Mission &amp; Work Process</h2>
                <p>Professional &amp; Dedicated Team</p>
              </div>
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-6'>
              <div className='icon-mi-left'>
                <i className='fa-solid fa-unlock-keyhole text-primary' />
                <div className='icon-mi-left-content'>
                  <h4>Fully Secure &amp; 24x7 Dedicated Support</h4>
                  <p>
                    If you are an individual client, or just a business startup
                    looking for good backlinks for your website.
                  </p>
                </div>
              </div>
              <div className='icon-mi-left'>
                <i className='fa-brands fa-twitter text-primary' />
                <div className='icon-mi-left-content'>
                  <h4>Manage your Social &amp; Busness Account Carefully</h4>
                  <p>
                    If you are an individual client, or just a business startup
                    looking for good backlinks for your website.
                  </p>
                </div>
              </div>
              <div className='icon-mi-left'>
                <i className='fa-solid fa-layer-group text-primary' />
                <div className='icon-mi-left-content'>
                  <h4>We are Very Hard Worker and loving</h4>
                  <p>
                    If you are an individual client, or just a business startup
                    looking for good backlinks for your website.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <img src='assets/img/vec-2.png' className='img-fluid' alt='' />
            </div>
          </div>
        </div>
      </section>
      {/* ================= Our Mission ================= */}
      {/* ============================ Call To Action ================================== */}
      <section className='theme-bg call-to-act-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='call-to-act'>
                <div className='call-to-act-head'>
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We will help you to grow your career and growth.</span>
                </div>
                <Link to='/' className='btn btn-call-to-act'>
                  SignUp Today
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============================ Call To Action End ================================== */}
    </div>
  );
}
